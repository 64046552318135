import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        language: 1,
        clientHeight: 630, // 动态控制table高度
        userData: { // 用户数据
            name: '',
            role: '',
            token: '',
            roleId: 0
        },
        roleId: 0 // 用户等级
    },
    mutations: {
        setClientHeight(state, val) {
            state.clientHeight = val
        },
        setUserData(state, obj) {
            state.userData = obj
        }
    },
    actions: {},
    modules: {}
})
