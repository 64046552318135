import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login/Login.vue')
},
    {
        path: '/404',
        name: 'page404',
        component: () => import('../views/404.vue')
    },
    {
        path: '/',
        redirect: '/Home',
        component: Home,
        name: 'Home'
    },
    {
        path: '/Home',
        name: 'Home',
        component: Home,
        children: [{
            path: '/Home/Home',
            name: 'Home/Home',
            component: () => import('../views/Home/Home.vue'),
        },
            {
                path: '/Home/UserNameList',
                name: 'Home/UserNameList',
                component: () => import('../views/UserNameList/UserNameList.vue'),
            },
            {
                path: '/Home/UserList',
                name: 'Home/UserList',
                component: () => import('../views/UserList/UserList.vue'),
            },
            {
                path: '/Home/EndUserList',
                name: 'Home/EndUserList',
                component: () => import('../views/EndUserList/EndUserList.vue'),
            },
            {
                path: '/Home/Device/DeviceList',
                name: 'Home/Device/DeviceList',
                component: () => import('../views/Device/DeviceList.vue'),
            },
            {
                path: '/Home/Device/DeviceModelList',
                name: 'Home/Device/DeviceModelList',
                component: () => import('../views/Device/DeviceModelList.vue'),
            },
            {
                path: '/Home/Device/DeviceNameList',
                name: 'Home/Device/DeviceNameList',
                component: () => import('../views/Device/DeviceNameList.vue'),
            },
            {
                path: '/Home/Device/DeviceTypeList',
                name: 'Home/Device/DeviceTypeList',
                component: () => import('../views/Device/DeviceTypeList.vue'),
            },
            {
                path: '/Home/DeviceState',
                name: 'Home/DeviceState',
                component: () => import('../views/DeviceState/DeviceState.vue'),
            },
            {
                path: '/Home/Report/ReportDeviceStatistics',
                name: 'Home/Report/ReportDeviceStatistics',
                component: () => import('../views/Report/ReportDeviceStatistics.vue'),
            },
            {
                path: '/Home/Report/ReportForm',
                name: 'Home/Report/ReportForm',
                component: () => import('../views/Report/ReportForm.vue'),
            },
            {
                path: '/Home/Report/ReportLog',
                name: 'Home/Report/ReportLog',
                component: () => import('../views/Report/ReportLog.vue'),
            },
            {
                path: '/Home/ChangePassword',
                name: 'Home/ChangePassword',
                component: () => import('../views/ChangePassword/ChangePassword.vue'),
            },
            // {
            //     path: '/Home/Reset',
            //     name: 'Home/Reset',
            //     component: () => import('../views/Reset/Reset.vue'),
            // },
            {
                path: '/Home/ChangeLog',
                name: 'Home/ChangeLog',
                component: () => import('../views/ChangeLog/ChangeLog.vue'),
            },
            {
                path: '/Home/UserNameList/AddUserNameList',
                name: 'Home/UserNameList/AddUserNameList',
                component: () => import('../views/UserNameList/AddUserNameList.vue'),
            },
            {
                path: '/Home/UserList/AddUserList',
                name: 'Home/UserList/AddUserList',
                component: () => import('../views/UserList/AddUserList.vue'),
            },
            {
                path: '/Home/EndUserList/AddEndUserList',
                name: 'Home/EndUserList/AddEndUserList',
                component: () => import('../views/EndUserList/AddEndUserList.vue'),
            },
            {
                path: '/Home/Device/AddDevice',
                name: 'Home/Device/AddDevice',
                component: () => import('../views/Device/AddDevice.vue'),
            },
            {
                path: '/Home/Device/SetDevice',
                name: 'Home/Device/SetDevice',
                component: () => import('../views/Device/SetDevice.vue'),
            },
            {
                path: '/Home/Device/formulaTemplate',
                name: 'Home/Device/formulaTemplate',
                component: () => import('../views/Device/formulaTemplate.vue'),
            },
            {
                path: '/Home/Device/EditTem',
                name: 'Home/Device/EditTem',
                component: () => import('../views/Device/EditTem.vue'),
            }
        ]
    },
    {
        path: '*', // 页面不存在的情况下会跳到404页面
        redirect: '/404',
        name: 'notFound',
        hidden: true
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    console.log(to.path)
    if (to.path == '/Home') {
        next('/Home/Home');
    }
    if (to.path == '/Login') { // 前往登录
        next();
    } else {
        let token = localStorage.getItem('u_token');
        if (token === null || token === '') { // 没有登录去登录
            next('/Login');
        } else {
            next();
        }
    }
});

export default router
