<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

var timer
export default {
  name: "app",
  components: {},
  mounted() {
    if (localStorage.getItem('language') == 2) {
      this.$store.state.language = 2
    }

    timer = setInterval(() => {
      // console.log(timer)
      if (localStorage.getItem('u_token')) {
      } else {
        // 跳出
        if (this.$route.name !== 'Login') {
          this.$router.push('/home')
        }
      }
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(timer)
  },
  methods: {}
};
</script>

<style lang="less">
html {
  min-width: 1500px;
  min-height: 800px;
  background: #FFFFFF;
  -webkit-user-select: none;
  -moz-user-select: none;
}

#app {
  width: 100%;
  height: 100%;
}
</style>
