const data = {
	asideList: [ // 侧边栏列表
		{
			name: '主页',
			index: '0',
			list: []
		},
		{
			name: '账号列表',
			index: '1',
			list: []
		},
		{
			name: '客户列表',
			index: '2',
			list: []
		},
		{
			name: '终端用户列表',
			index: '3',
			list: []
		},
		{
			name: '设备管理',
			index: '4',
			list: [{
					name: '设备列表',
					index: '4-0'
				},
				{
					name: '设备种类列表',
					index: '4-1'
				},
				{
					name: '设备名称列表',
					index: '4-2'
				},
				{
					name: '设备型号列表',
					index: '4-3'
				}
			]
		},
		{
			name: '设备状态',
			index: '5',
			list: []
		},
		{
			name: '报告',
			index: '6',
			list: [{
					name: '设备统计',
					index: '6-0'
				},
				{
					name: '化料/设备报表',
					index: '6-1'
				},
				{
					name: '日志记录',
					index: '6-2'
				}
			]
		},
		{
			name: '设备密码更改',
			index: '7',
			list: []
		},
		{
			name: '恢复出厂设置',
			index: '8',
			list: []
		},
		{
			name: '参数变更记录',
			index: '9',
			list: []
		}
	]
}
export default data
