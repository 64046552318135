const fun = {
  // 防抖
  debounce: function(fn, delay) {
    let timeout = null;
    return function() {
      // 清除之前遗留的计时器
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        fn.apply(this, arguments);
      }, delay);
    };
  },
  /**
   * 节流
   * 应用场景两种
   * 1.一个函数需要在等它处理完成后才被允许继续执行
   * 2.一个函数需要在规定时间内 只能允许执行一个
   */
  throttle: function(fn, delay) {
    // last为上一次触发回调的时间, timer是定时器
    let last = 0;
    let timer = null;
    // 将throttle处理结果当作函数返回

    return function() {
      // 记录本次触发回调的时间
      let now = +new Date();

      // 判断上次触发的时间和本次触发的时间差是否小于时间间隔的阈值
      if (now - last < delay) {
        // 如果时间间隔小于我们设定的时间间隔阈值，则为本次触发操作设立一个新的定时器
        clearTimeout(timer);
        timer = setTimeout(() => {
          last = now;
          fn.apply(this, arguments);
        }, delay);
      } else {
        // 如果时间间隔超出了我们设定的时间间隔阈值，那就不等了，无论如何要反馈给用户一次响应
        last = now;
        fn.apply(this, arguments);
      }
    };
  },
  getLanguageText(language, str) {
    if (language === 1) {
      return str;
    }

    let en = `
    Relay Mode
    Flush Settings
Confirm to log out?
Channel 
Formula Name
Chemical Name&Amount
loading
英文
Account
Password
Login
Notification
Account
Logout
Change Password
View EULA
Main Page
Account
Company
Device Management
Reports
Change Device Password
Customer Name
End User Name
Device Type
Device Name
Model Number
Serial Number
Working Duration
Location
Sequence
Email
Level
Company
Created On
New User
New Company
Search
Edit
Delete
Address
Email
Level
Country
Province/Region
City/District
Action
Device List
Settings
Device Status
Save Template
Load Template
System Settings
Formula Settings
Flush Settings
Price Settings
Flow Correction Settings
Effective Signal >=
Interval Water Flush
Air Flush
Water Flush before Dosing
Pump Working Time Limit
Final Water Flush
Sec.
2 sec. by default
pump will shut off
System Working Mode
Relay
Normal Mode
Manual Mode
Calibration Mode
Prime Mode
Remote Mode
Save
Load
Export
Formulas
Tunnel Washer
Channel 1
Channel 2
Channel 3
Channel 4
Formula No.
Chemical Name(right) - → Formula Name(down) - ↓
Chemical
Realtime
Alarm Log
Data Record
Installation Information
System Information
Channel 1
Channel 2
Channel 3
Channel 4
Formula
Pump
Air Flush
Water Flush
Signal
N/A
Reports
Current Working Mode
Wireless Signal Strength
Remote Control OFF
Remote Control ON
Dosing Pump Status
Operation Status:
Chemical
Occurrence Time
Recorded Time
Log
Port
Chemical Brand
Date of Installation
Model of the Tunnel Washer
Number of Formulas
Number of Chemicals
Product Name
Model Number
Serial Number
Hardware Version
Firmware Version
Device Type Mgmt
User Template
Template Name
Created by
Action
Trend
Details
Cost
Chemical
Formula
Alarm Summary
Change Log
Export
Table
Diagram
Sequence
Chemical Name
Total
Unit Cost
Total Cost
N/A
Channel 1
Channel 2
Channel 3
Channel 4
Counts
Liter
Counts Total
Channel/Loads
Channel 1
Channel 2
Channel 3
Channel 4
Dosing Amount
mL
Dosing Counts
Amount Total
Alarm Type
Signal Duration Too Long
Signal Duration Too Short
Signal Interval Too Short
Operation Time
Changed by
Log
Remote Control
please type a keyword
Select Device Type
Select Device Name
Select Model Number
Select Device Status
online
offline
N/A
End User Licence Agreement
Accept
hrs
mins
Account List
Select Account Level
Admin
Supervisor
Customer
End User
Assign to Supervisor
Assign to Customer
Assign to Company
enter username
enter your new password
enter your e-mail address
select Supervisor company
select Customer company
select End User company
OK
Cancel
Warning
This action will permanently delete this data, continue?
Confirm
New account added
Delete cancelled
Deleted!
select company level
Country
Region
Country/Region
items/page
go to
page
total
items
End User
Status
Add Device
enter serial number
enter IMEI number
select End User company
Device Password
enter device password for authentication
USD/Liter
Export
Select Channel
Select Valve Number
All
All
Select Valve Type
All
Chemical
Water
Air
Pump Valve
Action:
Air Valve
Water Valve
Chemical Valve
open
closed
to
Remote Mode:off
Remote Mode:on
Formula changed
value changed to
Device Type
Add Device Type
Add Device Name
Select Device Type
Edit Template
Submit
View by
Day
Month
Year
Serial Number
Relay
Total
Old/Initial Password
New Password
Confirm New Password
enter old/initial device password
enter new password
enter your new password again
select customer account
select end user account
Volume(Liters)
Total Volume(Liters)

Channel 1
Channel 2
Channel 3
Channel 4
USD
Liter
Total Volume
Password Changed!
Incorrect Password!
Welcome
Admin
Account
Company
Device
User Templates
User Templates
Unfold
Fold
End User
View Settings
Details
Log
Reports
Cost
Chemical
Formula
Alarm
Formula
Relay
USD/Liter
SUM
Volume
T
Touch to slide ← →
Serial No.
Formulas
Interval of Water Flush
Effective Signal >=
Air and Water Flush Settings
Channel 1
Channel 2
Channel 3
Channel 4
Interval of Water Flush
Final Water Flush
Formula Name:
Channel/Counts
Channel 1
Channel 2
Channel 3
Channel 4
Flow Correction
Price Settings
Channel 1
Channel 2
Channel 3
Channel 4
Chemical Counts
Chemical Volume
End User
Action: Air Valve 1 Closed
Action: Air Valve 1 Open
Action: Air Valve 2 Closed
Action: Air Valve 2 Open
Action: Air Valve 3 Closed
Action: Air Valve 3 Open
Action: Air Valve 4 Closed
Action: Air Valve 4 Open
Action: Water Valve 1 Closed
Action: Water Valve 1 Open
Action: Water Valve 2 Closed
Action: Water Valve 2 Open
Action: Water Valve 3 Closed
Action: Water Valve 3 Open
Action: Water Valve 4 Closed
Action: Water Valve 4 Open
Action: Pump Valve 1 Closed
Action: Pump Valve 1 Open
Action: Pump Valve 2 Closed
Action: Pump Valve 2 Open
Action: Pump Valve 3 Closed
Action: Pump Valve 3 Open
Action: Pump Valve 4 Closed
Action: Pump Valve 4 Open
Action: Chemical Valve 10 Closed
Action: Chemical Valve 10 Open
Action: Chemical Valve 1 Closed
Action: Chemical Valve 1 Open
Action: Chemical Valve 2 Closed
Action: Chemical Valve 2 Open
Action: Chemical Valve 3 Closed
Action: Chemical Valve 3 Open
Action: Chemical Valve 4 Closed
Action: Chemical Valve 4 Open
Action: Chemical Valve 5 Closed
Action: Chemical Valve 5 Open
Action: Chemical Valve 6 Closed
Action: Chemical Valve 6 Open
Action: Chemical Valve 7 Closed
Action: Chemical Valve 7 Open
Action: Chemical Valve 8 Closed
Action: Chemical Valve 8 Open
Action: Chemical Valve 9 Closed
Action: Chemical Valve 9 Open
Status: Relay Mode Started
Status: Normal Mode Started
Status: Relay Mode Stopped
Status: Normal Mode Stopped
All
None
Formula 1
Formula 2
Formula 3
Formula 4
Formula 5
Formula 6
Formula 7
Formula 8
Formula 9
Formula 10
Formula 11
Formula 12
Formula 13
Formula 14
Formula 15
Select Alarm Type  （我们有这一项吗？在什么页面上？）
End Date
Start Date
Dosing Amount (mL)
Amount Total (Liter)
Account cannot be empty!
Password cannot be empty!
Incorrect password!
Successful login:
Requesting data…
open
W-Flush
A-Flush
Dosing
Export Log
Signal 1 Duration Too Short
Signal 2 Duration Too Short
Signal 3 Duration Too Short
Signal 4 Duration Too Short
Signal 5 Duration Too Short
Signal 6 Duration Too Short
Signal 7 Duration Too Short
Signal 8 Duration Too Short
Signal 9 Duration Too Short
Signal 10 Duration Too Short
Signal 1 Duration Too Long
Signal 2 Duration Too Long
Signal 3 Duration Too Long
Signal 4 Duration Too Long
Signal 5 Duration Too Long
Signal 6 Duration Too Long
Signal 7 Duration Too Long
Signal 8 Duration Too Long
Signal 9 Duration Too Long
Signal 10 Duration Too Long
Signal 1 Interval Too Short
Signal 2 Interval Too Short
Signal 3 Interval Too Short
Signal 4 Interval Too Short
Signal 5 Interval Too Short
Signal 6 Interval Too Short
Signal 7 Interval Too Short
Signal 8 Interval Too Short
Signal 9 Interval Too Short
Signal 10 Interval Too Short
Please click the following link to download the report!
Export Log
Confirm to close？
Passwords don’t match
Successful Login:
Admin
Admin
Admin
Supervisor
Supervisor
Supervisor
Supervisor
Supervisor
Customer
Mailbox account cannot be empty!
Admin
Assign to company cannot be empty!
This action will permanently delete this data, continue?
Deleted!
End User
please enter
please select
Company name cannot be empty!
Company address cannot be empty!
Asssinged to End User
New device added successfully
Delete cancelled!
Delete cancelled!
This action will permanently delete this device, continue?
Please enter device password to proceed
enter device password
Cancel
Warning
Confirm
Please enter template name
Action cancelled
Load Template
Select your template to override
All
All
All
[New device name] added successfully
[New device type] added successfully
[New model number] added successfully
This action will permanently delete this data, continue?
Deleted!
Sec. (2 by default)
Sec. (pump will shut off)
Formula Name\Chemical Name
Air Flush Time Setting (sec.)
Water Flush Time Setting (sec.)
Final Water Flush Time Setting (sec.)
Channel 1
Channel 2
Channel 3
Channel 4
USD/Liter
please enter
This action will permanently delete this template, continue?
Confirm to modify this template?
Template modified successfully
Modification cancelled
Unit Cost (USD/Liter)
Remote Mode:off
Remote Mode:on
Formula settings changed
System settings changed
Select Device Serial Number
Old password cannot be empty!
New password cannot be empty!
Confirm password cannot be empty!
Incorrect password!
Please enter the old password
Please enter the new password
Please confirm on the new password
Formula Name
Chemical Name&Amount
Effective Signal Duration
Formula Name:
Template Saved Successfully
Missing Parameters
Account
Operation Status
Total Volume (Liter)
Unit Cost (USD/Liter)
Counts - Channel 1
Counts - Channel 2
Counts - Channel 3
Counts - Channel 4
Volume (Liter) - Channel 1
Volume (Liter) - Channel 2
Volume (Liter) - Channel 3
Volume (Liter) - Channel 4
Total Volume (Liter)
Total Volume (Liter)
Formula 1
Formula 2
Formula 3
Formula 4
Formula 5
Formula 6
Formula 7
Formula 8
Formula 9
Formula 10
Formula 11
Formula 12
Formula 13
Formula 14
Formula 15
Loads
Dosing Amount (mL)
Total Volume (Liter)
Template Loaded Successfully
Action: Remote Control ON --Success
Action: Remote Control OFF -- Success
Action: Remote Control ON -- Fail
Action: Remote Control OFF -- Fail
`;
    let ch = `
时间模式
配方设定2
是否退出登录
洗衣龙仓
当前配方
所加药剂名称及药量
加载中
中文
账号名称
账号密码
登录
通知
账号
退出
修改密码
查阅EULA
主页
账号列表
公司列表
设备管理
报告
设备密码更改
客户名称
终端客户名称
设备种类
设备名称
设备型号
设备序列号
设备持续工作时间
位置
序号
邮箱
账号类型
公司名称
创建日期
添加账号
添加公司
搜索
编辑
删除
公司地址
公司邮箱
公司类型
国家
省份
城市
操作
设备列表
参数设定
设备状态
保存模板
选择模板
基本设定
配方设定1
配方设定2 
化料单价设定
药剂系数修正
有效信号认定时长大于
药剂间冲水时间
气冲时间
泵开启后冲水时间
泵体最长执行时间
最终冲水时间
秒
默认2秒
超过时间关停
工作模式选择
时间模式
编程模式
手动模式
泵校准模式
加料模式
远程模式
修改
读取
导出表单
配方参数设定
洗衣龙
洗衣龙1
洗衣龙2
洗衣龙3
洗衣龙4
配方编号
配方名称\\药剂名称
编号
实时状态
报警日志
数据记录
安装信息
系统信息
通道一
通道二
通道三
通道四
配方
泵
气冲
水冲
信号
无
设备报表
当前工作模式
无线信号强度
远程控制已关闭
远程控制已开启
泵状态
运行状态
当前所加药剂
发生时间
记录时间
事件
端口
化料品牌
安装时间
洗衣龙型号规格
配方数量
化料数量
产品名称
产品型号
产品序列号
硬件版本
固件版本
设备类型管理
配方模板
模板名称
创建用户
设置
设备统计
化料/设备报表
成本统计
化料统计
配方统计
报警信息
参数变更记录
导出报表
表格
统计
化料编号
化料名称
总量
单价
总价
暂无数据
洗衣仓1
洗衣仓2
洗衣仓3
洗衣仓4
次数
升
总次数
洗衣龙/执行次数
洗衣龙仓1
洗衣龙仓2
洗衣龙仓3
洗衣龙仓4
加药量设定
毫升
加药次数
加药总量
报警信息种类
信号时间过长
信号时间过短
信号间隔过短
操作时间
操作账号
变更内容
远程使能
请输入关键词进行搜索
请选择设备类型
请选择设备名称
请选择设备型号
请选择设备状态
在线
离线
无数据
最终用户许可协议
同意
小时
分钟
账户列表
请选择账号类型
超级管理
管理员
客户
终端用户
管理员公司
客户公司
从属公司
请输入用户账号
请输入用户密码
请输入用户邮箱
请选择管理员公司
请选择客户公司
请选择从属公司
确认
取消
提示
此操作将永久删除改数据，是否继续？
确定
添加账户成功
取消删除
删除成功
请选择公司类型
请选择国家
请选择城市
国家/城市
条/页
前往
页
共
条
终端公司名称
状态
添加设备
请输入序列号
请输入IMEI
请选择终端用户公司
设备密码
请输入设备密码进行验证
RMB/Liter
导出表单(配方)
请选择通道
请选择端口
全部通道
全部端口
请选择阀门类型
全部类型
液
水
气
泵阀
动作：
气阀
水阀
液阀
打开
关闭
至
远程使能:关
远程使能:开
配方修改
值变为
设备类型
添加设备类型
添加设备名称
请选择活动区域
模板编辑
提交
请选择时间单位
日
月
年
机器序列号
时间
总计
旧密码/初始密码
新密码
确认新密码
请输入旧密码/初始密码
请输入密码
请再次输入新密码
请选择客户名称
请选择终端客户名称
总量(升)
总量(升)
次
洗衣仓一
洗衣仓二
洗衣仓三
洗衣仓四
元
升
化料总量
修改密码成功
修改密码失败
欢迎
超级管理员
账号信息
公司信息
设备信息
用户配方
用户模板
展开
收起
终端客户公司
查看参数
详情
日志记录
统计报告
Cost Summary
Chemical Usage
Formula Summary
报警统计
配方模式
Relay Mode
RMB/Liter
合计
总重量
总
下方区域可以左右滑动
序列号
配方设定
不同药剂加料之间的冲水时间
Effective Signal >=
气冲,水冲时间设定
通道1
通道2
通道3
通道4
中间水冲时间
最终水冲时间
配方名称:
洗衣仓/执行次数
洗衣龙仓1
洗衣龙仓2
洗衣龙仓3
洗衣龙仓4
药剂系数修正
化料单价设定
洗衣仓一
洗衣仓二
洗衣仓三
洗衣仓四
化料统计次数
化料统计剂量
终端
动作: 气阀1关闭
动作: 气阀1打开
动作: 气阀2关闭
动作: 气阀2打开
动作: 气阀3关闭
动作: 气阀3打开
动作: 气阀4关闭
动作: 气阀4打开
动作: 水阀1关闭
动作: 水阀1打开
动作: 水阀2关闭
动作: 水阀2打开
动作: 水阀3关闭
动作: 水阀3打开
动作: 水阀4关闭
动作: 水阀4打开
动作: 泵阀1关闭
动作: 泵阀1打开
动作: 泵阀2关闭
动作: 泵阀2打开
动作: 泵阀3关闭
动作: 泵阀3打开
动作: 泵阀4关闭
动作: 泵阀4打开
动作: 液阀10关闭
动作: 液阀10打开
动作: 液阀1关闭
动作: 液阀1打开
动作: 液阀2关闭
动作: 液阀2打开
动作: 液阀3关闭
动作: 液阀3打开
动作: 液阀4关闭
动作: 液阀4打开
动作: 液阀5关闭
动作: 液阀5打开
动作: 液阀6关闭
动作: 液阀6打开
动作: 液阀7关闭
动作: 液阀7打开
动作: 液阀8关闭
动作: 液阀8打开
动作: 液阀9关闭
动作: 液阀9打开
状态: 进入时间控制模式
状态: 进入编程控制模式
状态: 退出时间控制模式
状态: 退出编程控制模式
全部
无配方
配方1名称
配方2名称
配方3名称
配方4名称
配方5名称
配方6名称
配方7名称
配方8名称
配方9名称
配方10名称
配方11名称
配方12名称
配方13名称
配方14名称
配方15名称
请选择报警类型
结束日期
开始日期
加药量设定（毫升）
加药总量（升)
账号不能为空
密码不能为空
密码错误
登陆成功:
等待请求
开启
冲水
冲气
加药中
下载报警日志
报警: 1信号时间过短
报警: 2信号时间过短
报警: 3信号时间过短
报警: 4信号时间过短
报警: 5信号时间过短
报警: 6信号时间过短
报警: 7信号时间过短
报警: 8信号时间过短
报警: 9信号时间过短
报警: 10信号时间过短
报警: 1信号时间过长
报警: 2信号时间过长
报警: 3信号时间过长
报警: 4信号时间过长
报警: 5信号时间过长
报警: 6信号时间过长
报警: 7信号时间过长
报警: 8信号时间过长
报警: 9信号时间过长
报警: 10信号时间过长
报警: 1信号间隔过短
报警: 2信号间隔过短
报警: 3信号间隔过短
报警: 4信号间隔过短
报警: 5信号间隔过短
报警: 6信号间隔过短
报警: 7信号间隔过短
报警: 8信号间隔过短
报警: 9信号间隔过短
报警: 10信号间隔过短
excel导出表单下载地址
下载数据日志
确认关闭？
两次输入密码不一致
登录成功:
超级管理
超级管理
超级管理
管理员
管理员
管理员
管理员
管理员
客户
邮箱不能为空
超管
从属公司不能为空
此操作将永久删除该数据, 是否继续?
删除成功!
终端
请输入
请选择
公司名称不能为空
公司地址不能为空
终端用户公司
添加设备成功
已取消删除！
已取消删除
此操作将永久删除该设备, 是否继续?
请输入设备密码执行删除操作
请输入设备密码
取消
提示
确定
请输入模板名称
取消操作
选择模板
请选择模板
全部设备类型
全部设备名称
全部设备型号
添加成功[设备名称]
添加成功[设备类型]
添加成功[设备型号]
此操作将永久删除该节点，是否继续？
删除成功！
秒(默认两秒)
秒(超过时间关停)
配方名称\\药剂名称
气冲时间(秒)
水冲时间(秒)
最终水冲时间(秒)
泵1
泵2
泵3
泵4
RMB/Liter
请输入内容
此操作将永久删除该参数配置模板，是否继续？
是否确认修改模板？
修改模板成功
已取消修改
Unit Cost(RMB/Liter)
远程使能:关
远程使能:开
配方修改
系统参数修改
请选择设备序列号
原始密码不能为空
新密码不能为空
确认新密码不能为空
删除设备需要密码
请输入旧密码
请输入新密码
请输入确认新密码
当前配方
所加药剂名称及药量
有效信号认定时长
配方名称:
保存模板成功
缺少参数
用户名
运行状态
总计(升)
价格RMB/升
洗衣龙仓1(次数)
洗衣龙仓2(次数)
洗衣龙仓3(次数)
洗衣龙仓4(次数)
洗衣龙仓1(总量/升)
洗衣龙仓2(总量/升)
洗衣龙仓3(总量/升)
洗衣龙仓4(总量/升)
总计/升
加药总量（升）
配方1
配方2
配方3
配方4
配方5
配方6
配方7
配方8
配方9
配方10
配方11
配方12
配方13
配方14
配方15
已执行次数
加药量设置(毫升)
加药总量(升)
模板设置成功
下发指令:远程控制关闭--成功
下发指令:远程控制开启--成功
下发指令:远程控制关闭--失败
下发指令:远程控制开启--失败
`;

    en = en.split("\n");
    ch = ch.split("\n");
    try {
      let i = ch.indexOf(str);
      if (i === -1) {
        return str;
      }
      return en[i];
    } catch (e) {
      return str;
    }
    return str;
  },
};
export default fun;
