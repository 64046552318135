import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/normal.css'
import common from '../src/common/common.js'
import axios from 'axios'; /* 引入axios进行地址访问*/
import fun from "./common/utils";
// import './assets/theme/index.css'

Vue.config.productionTip = false
Vue.prototype.common = common
Vue.prototype.$http = axios
axios.defaults.baseURL = 'http://www.hydro.local/v1/'
axios.defaults.baseURL = 'https://hydro.jihepingfang.com/v1/'
axios.defaults.baseURL = 'https://hcdata.hydrosystemschina.com/v1/'

Vue.prototype.fun = fun

// 请求拦截
axios.interceptors.request.use(
	config => {
		if (localStorage.getItem('u_token')) {
			config.data.token = localStorage.getItem('u_token');
		}

		console.log(config)
		return config;
	},
	error => {
		return Promise.reject(error);
	});
axios.interceptors.response.use(
	res => {
		if (res?.data?.msg === '请重新登陆') {
			localStorage.removeItem("u_token");
			this.$router.push('/')
		}
		return res;
	}, err => {
		return Promise.reject(err)
	})
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

