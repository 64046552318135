<template>
  <el-container style="height: 100%;">
    <!-- 头部区域 -->
    <el-header height="50px" style="background-color: #0080C1; color: #FFFFFF;">
      <!-- 头部区域-左侧 -->
      <div class="header-left" style="background-color: #FFFFFF;height: 100%;">
        <!-- 头部区域-左侧-LOGO -->
        <div class="header-logo"></div>
        <!-- 头部区域-左侧-公司名称 -->
        <div class="header-title"></div>
      </div>
      <!-- 头部区域-右侧 -->
      <div class="header-right">
        <!-- 头部区域-右侧-通知 -->
        <!--        <el-dropdown @command="command1">-->
        <!--          <el-link style="color: #FFFFFF;">{{-->
        <!--              $store.state.language === 1 ? '中文' : 'English'-->
        <!--            }}<i class="el-icon-arrow-down"></i></el-link>-->
        <!--          <el-dropdown-menu slot="dropdown">-->
        <!--            <el-dropdown-item command="1">中文</el-dropdown-item>-->
        <!--            <el-dropdown-item command="2">English</el-dropdown-item>-->
        <!--          </el-dropdown-menu>-->
        <!--        </el-dropdown>-->

        <el-dropdown @command="command">
          <el-link icon="el-icon-edit" style="color: #FFFFFF;">{{
            $store.state.language === 1 ? '账号' : 'Account'
          }}:{{ username }}
          </el-link>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">{{
              $store.state.language === 1 ? '修改密码' : 'Change Password'
            }}
            </el-dropdown-item>
            <el-dropdown-item command="2">{{
              $store.state.language === 1 ? '查阅EULA' : 'View EULA'
            }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <!-- 头部区域-右侧-退出 -->
        <el-button icon="el-icon-close" size="small" type="primary" @click="loginout">{{
          $store.state.language === 1 ? '退出' : 'Logout'
        }}
        </el-button>
      </div>
    </el-header>
    <!-- 主体区域 -->
    <el-container style="min-height:600px">
      <!-- 侧边栏 -->
      <el-aside width="201px" >
        <!-- 侧边导航功能 -->
        <el-menu :default-active="active" :router="router" active-text-color="#FFFFFF" background-color="#0181C5"
          style="height: 100%;" text-color="#ABC2CE" unique-opened @select="select">
          <el-menu-item :router="router" index="/Home/Home">{{
            $store.state.language === 1 ? '主页' : 'Main Page'
          }}
          </el-menu-item>
          <el-menu-item :router="router" index="/Home/UserNameList">{{
            $store.state.language === 1 ? '账号列表' : 'Account'
          }}
          </el-menu-item>
          <el-menu-item :router="router" index="/Home/UserList">{{
            $store.state.language === 1 ? '公司列表' : 'Company'
          }}
          </el-menu-item>
          <!-- <el-menu-item index="/Home/EndUserList" :router="router">终端用户列表</el-menu-item> -->
          <el-submenu :router="router" index="设备管理">
            <template slot="title">
              <!-- <i class="el-icon-location"></i> -->
              <span slot="title">{{
                $store.state.language === 1 ? '设备管理' : 'Device Management'
              }}</span>
            </template>
            <el-menu-item :router="router" index="/Home/Device/DeviceList">{{
              $store.state.language === 1 ? '设备列表' : 'Device List'
            }}
            </el-menu-item>
            <el-menu-item :disabled="$store.state.roleId > 2" :router="router" index="/Home/Device/DeviceTypeList">{{
              $store.state.language === 1 ? '设备类型管理' : 'Device Type Mgmt'
            }}
            </el-menu-item>
            <el-menu-item :router="router" index="/Home/Device/formulaTemplate">{{
              $store.state.language === 1 ? '配方模板' : 'User Template'
            }}
            </el-menu-item>
            <!--
						<el-menu-item index="/Home/Device/DeviceNameList" disabled :router="router">设备名称列表
						</el-menu-item>
						<el-menu-item index="/Home/Device/DeviceModelList" disabled :router="router">设备型号列表
						</el-menu-item>
						-->
          </el-submenu>
          <el-menu-item v-show="false" :router="router" disabled index="/Home/DeviceState">{{
            $store.state.language === 1 ? '设备状态' : 'Status'
          }}
          </el-menu-item>
          <el-submenu :router="router" index="报告">
            <template slot="title">
              <!-- <i class="el-icon-location"></i> -->
              <span slot="title">{{
                $store.state.language === 1 ? '报告' : 'Reports'
              }}</span>
            </template>
            <el-menu-item :router="router" index="/Home/Report/ReportDeviceStatistics">{{
              $store.state.language === 1 ? '设备统计' : 'Trend'
            }}
            </el-menu-item>
            <el-menu-item :router="router" index="/Home/Report/ReportForm">{{
              $store.state.language === 1 ? '化料/设备报表' : 'Details'
            }}
            </el-menu-item>
            <!--
						<el-menu-item index="/Home/Report/ReportLog" disabled :router="router">日志记录</el-menu-item>
						-->
          </el-submenu>
          <el-menu-item :disabled="this.$store.state.roleId == 4" :router="router" index="/Home/ChangePassword">
            {{
              $store.state.language === 1 ? '设备密码更改' : 'Change Device Password'
            }}
          </el-menu-item>
          <!--          <el-menu-item :disabled="this.$store.state.roleId==4" :router="router" index="/Home/Reset">恢复出厂设置-->
          <!--          </el-menu-item>-->
          <!--          <el-menu-item :router="router" index="/Home/ChangeLog">参数变更记录</el-menu-item>-->
        </el-menu>
      </el-aside>
      <!-- 主要显示 -->
      <el-main>
        <el-card class="box-card">
          <router-view></router-view>
        </el-card>
      </el-main>
    </el-container>
    <!--    <el-dialog :visible.sync="dialogVisible" title="最终用户许可协议">-->
    <!--      <div>-->
    <!--        最终用户许可协议-->
    <!--      </div>-->
    <!--          <el-button @click="dialogVisible = false">取 消</el-button>-->
    <!--          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
    <!--    </el-dialog>-->
    <el-dialog :close-on-click-modal="false" :show-close="false" :visible.sync="dialogVisible"
      :title="fun.getLanguageText($store.state.language, '最终用户许可协议')" width="30%">
      <div style="max-height: 200px;overflow-y: auto" v-if="$store.state.language === 1">
        <div class=WordSection1>

          <p align=center class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;
margin-bottom:6.0pt;margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:
0cm;mso-para-margin-bottom:.5gd;mso-para-margin-left:0cm;text-align:center;
line-height:normal;background:white'><b style='mso-bidi-font-weight:normal'><span style='font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:
"Times New Roman";color:#212529'>最终用户注册和使用协议</span></b><span lang=EN-US
              style='font-family:"Times New Roman",serif;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;line-height:normal;background:white'><b style='mso-bidi-font-weight:normal'><span
                lang=EN-US style='font-family:"Times New Roman",serif;
color:#212529'></span></b></p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;line-height:normal;background:white'><span class=GramE><b
                style='mso-bidi-font-weight:normal'><span style='font-size:10.0pt;
mso-bidi-font-size:11.0pt;font-family:等线;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin;color:black;
mso-color-alt:windowtext'>百士吉泵业</span></b></span><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;mso-bidi-font-size:11.0pt;font-family:
等线;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin;
color:black;mso-color-alt:windowtext'>（天津）有限公司</span></b><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'>(</span></b><b style='mso-bidi-font-weight:normal'><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>以下简称“百士吉”</span></b><b style='mso-bidi-font-weight:normal'><span
                lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'>)</span></b><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'> </span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:
"Times New Roman";mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#212529'>将通过</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'>https://hydroconnect.hydrosystemschina.com</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>（“本网站”）向您（以下又简称“<u>用户</u>”，连同<span
                class=GramE>百士吉一起</span>，称为“<u>双方</u>”，单独称“<u>一方</u>”）提供</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'>Hydro Connect</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>软件作为一项服务（以下简称“<u>服务</u>”），用于监控该服务集成的化学品分配设备的使用和效率。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;line-height:normal;background:white'><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;line-height:normal;background:white'><b style='mso-bidi-font-weight:normal'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>在<span class=GramE>您注册</span>成为用户前或使用服务前，请仔细阅读以下条款和条件，如果您不同意本协议的条款和条件，请不要继续后续操作或<span
                  class=GramE>勿访问</span>或以其它方式使用服务。若<span
                  class=GramE>您注册</span>成为用户或访问或以其它方式使用任何部分服务，则表示您接受并同意本协议的条款和条件。</span></b><b><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#333333'>本协议为<span
                  class=GramE>百士吉和</span>用户之间的协议，对双方具有法律效力，适用于您在本网站的全部活动。</span></b><b
              style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'></span></b></p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;line-height:normal;background:white'><b style='mso-bidi-font-weight:normal'><span
                lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'></span></b></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l4 level1 lfo1;background:white'>
            <![if !supportLists]><b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:"Times New Roman";color:#212529'><span style='mso-list:Ignore'>1.<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span></span></b>
            <![endif]><b style='mso-bidi-font-weight:normal'><u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>本协议</span></u></b><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>1.1<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>内容</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。<span class=GramE>百士吉有权</span>根据需要不定时地制定、修改本协议内容，如本协议内容有任何变更，一切变更以本网站最新公布的内容为准。<span
                class=GramE>百士吉将</span>第一时间刊载并公告本协议变更情况。经修订的协议一经公布，立即自动生效，对新协议生效之后注册的用户发生法律效力。对于新协议生效之前注册的用户，若用户在新协议生效后继续使用本网站提供的服务，则表明用户已充分阅读并认可和同意遵守新协议内容。</span><span
              lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;background:white'><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>1.2<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>注册</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。您在本网站获得注册后，方可成为<span class=GramE>百士吉的</span>用户。您一旦注册成功成为用户，<span class=GramE>百士吉将</span>为您分配访问和使用<span
                class=GramE>本服务</span>的唯一用户标识名称和密码（以下简称“<u>用户</u></span><u><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>ID</span></u><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>”）。在注册过程中，您必须根据本网站的要求提供最新、真实、有效及完整的资料。<span
                class=GramE>百士吉将</span>认真、全面审核用户提交的资料以确认用户真实身份，确保后续服务的合法性、有效性。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>1.3<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>安全</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。用户应遵守与使用服务相关的所有公司规章制度和安全限制。用户应全权负责确保其用户</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'>ID</span><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>的安全性和保密性。用户承认，其将对因使用其用户</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>ID</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>而产生的所有责任承担全部责任，且其用户</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>ID</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>下的任何交易都将被视为由用户执行。若使用除本协议规定外的用户</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>ID</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>，应被视为用户违反本协议。用户不得与服务的其它任何用户共享其用户</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>ID</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;background:white'><u><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'><span style='text-decoration:none'>&nbsp;</span></span></u></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;background:white'><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#333333'>如果用户信息、资料有任何变动，必须及时更新。因用户提供的信息资料不准确、不真实而引发的一切后果由用户自行承担。用户不得将其<span
                class=GramE>帐号</span>、密码转让、出借或以任何脱离用户控制的形式交由他人使用。如果用户发现其<span
                class=GramE>帐号</span>遭他人非法使用，应立即通知百士吉。因黑客行为或用户的保管疏忽导致<span class=GramE>帐号</span>、密码遭他人非法使用，<span
                class=GramE>百士吉不</span>承担任何责任。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span></p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;background:white'><span lang=EN-US style='font-size:10.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>1.4<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>专有权利</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。用户承认<span class=GramE>百士吉在</span>服务和相关文件中的专有权利应保护其专有性。如果用户对<span
                class=GramE>百士吉随后</span>包含在服务（或任何其它软件或服务）中的服务提出任何新的特性、功能或性能建议，用户在此承认（</span><span class=SpellE><span
                lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:
等线;mso-fareast-theme-font:minor-fareast;color:#212529'>i</span></span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>）百<span class=GramE>士吉应</span>拥有并有权使用此类建议，包含此类新特性、功能或性能的服务（或任何其它服务）应为<span
                class=GramE>百士吉的</span>专有财产；以及（</span><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'>ii</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>）所有此类建议应不受其它任何可能强加<span class=GramE>给百士吉</span>的保密限制。未经<span
                class=GramE>百士吉事先</span>书面授权，用户不得全部或部分反汇编、反编译、反向工程、修改、转录、存储、翻译、出售、转售、租赁或以其它方式转让或分发<span
                class=GramE>本服务</span>或其相关文档。如果出现任何违反第</span><u><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'>1.3</span></u><u><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>条</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>的情况，用户同意<span class=GramE>百士吉将</span>遭受不可挽回的损害，因此有权要求用户赔偿。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;background:white'><span lang=EN-US style='font-size:10.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>1.5<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>第三方软件；分包</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。如果服务包含<span class=GramE>百士吉许可</span>的任何第三方软件，则除本协议规定的条款外，用户还必须遵守适用于此类第三方软件的任何附加条款、限制或限定。此外，用户承认<span
                class=GramE>百士吉有权</span>分包其托管服务的履行，在此情况下，任何第三方托管提供商提供的服务条款（包括但不限于比百<span
                class=GramE>士吉提供</span>的服务级别更严格的任何服务级别）都将通过引用并入本协议。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;background:white'><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l4 level1 lfo1;background:white'>
            <![if !supportLists]><b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:"Times New Roman";color:#212529'><span style='mso-list:Ignore'>2.<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span></span></b>
            <![endif]><b style='mso-bidi-font-weight:normal'><u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>数据权利</span></u></b><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>2.1<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>用户信息</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。用户向<span class=GramE>百士吉授予</span>非排他性许可，仅在百<span
                class=GramE>士吉提供</span>服务需要的情况下复制、使用和显示用户在访问服务时发送或输入的任何和所有个人可识别数据、信息或通信（以下简称“<u>用户信息</u>”）。根据下文<u>第</u></span><u><span
                lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>2.3</span></u><u><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>条</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>的规定，用户应拥有并保留所有用户信息的所有权利、所有权和权益，包括所有知识产权。用户承认，<span
                class=GramE>百士吉对</span>用户信息的内容无任何控制权，用户唯一的责任是提供信息并确保用户传输或接收的信息符合现行或未来颁布的所有适用法律法规，并且费用由用户承担。然而，百<span
                class=GramE>士吉无</span>义务出于准确性、潜在责任或任何其它原因审查用户信息。</span><span lang=EN-US
              style='font-size:10.0pt;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>2.2<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>数据保留</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。用户同意，<span class=GramE>百士吉保留</span>或维护在履行服务过程中获得的任何用户信息的义务不得超过本协议终止后的十四（</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>14</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>）天。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>2.3<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>数据收集</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。<span
                class=GramE>百士吉有</span>权利用数据捕获、聚合和分析工具以及其它类似工具，提取、编译、合成和分析用户访问和使用服务产生的任何非个人身份数据或信息（包括但不限于用户信息，以下简称“<u>盲数据</u>”）。在<span
                class=GramE>百士吉收集</span>任何<span class=GramE>盲</span>数据的情况下，此类<span class=GramE>盲</span>数据应完全归<span
                class=GramE>百士吉所有</span>，百<span
                class=GramE>士吉可</span>将其用于任何合法商业目的，无需对用户承担任何责任，包括但不限于为用户提供机会，让用户可以与其同行进行比较，前提是<span
                class=GramE>盲数据</span>仅以汇总形式使用，而不需要明确指出<span class=GramE>盲</span>数据的来源。在不承担用户任何义务或责任的情况下，百<span
                class=GramE>士吉同意尽商业</span>上合理的努力遵守关于传播和使用此类<span class=GramE>盲</span>数据的适用中国法律和法规。</span><span lang=EN-US
              style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>2.4<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>隐私政策</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。百<span class=GramE>士吉采取</span>了合理的安全措施来保护个人身份信息和用户使用服务时产生并由<span
                class=GramE>百士吉接收</span>的敏感数据。虽然没有一个计算机系统或服务器是完全安全的，但<span
                class=GramE>百士吉认为</span>其所实施的措施减少了安全问题。因此，附上了<span class=GramE>百士吉的</span>隐私政策作为附件</span><span lang=EN-US
              style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'>A</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>，通过引用纳入本协议。</span><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>2.5<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>数据安全性</span></u><span
              style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。该服务旨在通过网络接口连接到信息和数据，并传递信息和数据。用户的唯一责任是提供并持续确保服务与用户计算机和信息技术网络或任何其它网络（视情况而定）之间的安全连接。用户应制定和维护任何适当的网络安全措施（包括但不限于安装防火墙、安装防病毒程序和定期数据备份），以保护服务和用户的系统和网络免受任何安全漏洞、未经授权的访问、干扰、入侵或数据或信息被盗。</span><span
              lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;background:white'><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l4 level1 lfo1;background:white'>
            <![if !supportLists]><b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:"Times New Roman";color:#212529'><span style='mso-list:Ignore'>3.<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span></span></b>
            <![endif]><b style='mso-bidi-font-weight:normal'><u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>用户义务</span></u></b><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;background:white'><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>3.1<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>协助</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。用户应提供百<span class=GramE>士吉要求</span>的所有合理协助和合作，以提供与服务相关的任何支持或故障诊断协助。<span
                class=GramE>百士吉保留</span>对用户造成的全部或部分延迟收取额外费用的权利。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'></span>
          </p>

          <p class=MsoNormal><u><span lang=EN-US style='font-size:10.0pt;line-height:
107%;font-family:"Times New Roman",serif;color:#212529'><span style='text-decoration:none'>&nbsp;</span></span></u></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>3.2<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>硬件</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。用户应采购、安装和维护用户连接和访问服务所需的所有设备、互联网连接和其它硬件（构成百<span class=GramE>士吉设施</span>维护的支持中心的硬件除外）。</span><span
              lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;background:white'><span lang=EN-US style='font-size:10.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>3.3<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>行为</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。用户同意不会：（</span><span class=SpellE><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'>i</span></span><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>）复制、反编译、反向工程、反汇编、试图导出源代码、修改或创建服务或任何服务相关文档的衍生作品；（</span><span
              lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>ii</span><span
              style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>）以任何方式上传或发布包含病毒、特洛伊木马、蠕虫、定时炸弹、逻辑炸弹、损坏文件或任何其它可能损害服务或他人计算机运行的类似软件或程序的文件；（</span><span lang=EN-US
              style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>iii</span><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>）将服务用于非法目的；（</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>iv</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>）干扰或破坏与服务连接的网络；（</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>v</span><span
              style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>）通过服务上传、发布、宣传或传播任何非法、骚扰、诽谤、辱骂、威胁、有害、粗俗、淫秽、仇恨、种族、人种或其它任何种类或性质的令人反感的材料；（</span><span lang=EN-US
              style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>vi</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>）<span class=GramE>不时上传超过百士吉规定</span>的任何限额的数据和</span><span
              lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>/</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>或材料，<span
                class=GramE>不</span>创建大量账户或以其它方式传输大量数据，以免阻塞服务或构成拒绝服务攻击或以其它方式对服务产生不利影响；或（</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>vii</span><span
              style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>）上传、推广、传播或发布任何鼓励可能构成刑事犯罪或引起民事责任的行为的材料。如果用户违反这些限制，百<span
                class=GramE>士吉可</span>采取其自行认为适当的一切补救措施，包括但不限于立即暂停或取消服务。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;background:white'><span lang=EN-US style='font-size:10.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>3.4<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>用户合<span class=GramE>规</span>要求</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:
等线;mso-fareast-theme-font:minor-fareast;color:#212529'><span style='mso-spacerun:yes'>&nbsp; </span></span><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>用户同意：</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.0pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.0pt;text-indent:-18.0pt;
mso-list:l1 level1 lfo2;background:white'><a name="_Hlk97129584">
              <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
                  </span></span></span>
              <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>遵守中国有关的法律和法规；</span>
            </a><span style='mso-bookmark:_Hlk97129584'><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'></span></span></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.0pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.0pt;text-indent:-18.0pt;
mso-list:l1 level1 lfo2;background:white'><span style='mso-bookmark:_Hlk97129584'>
              <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
                  </span></span></span>
              <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>遵守所有与网络服务有关的网络协议、规定和程序；</span>
            </span><span style='mso-bookmark:_Hlk97129584'><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'></span></span></p>

          <span style='mso-bookmark:_Hlk97129584'></span>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.0pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.0pt;text-indent:-18.0pt;
mso-list:l1 level1 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span
              style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>获得通过服务提供或以其它方式处理任何数据和内容所需的所有必要的书面同意和授权，用户声明并保证已获得所有此类必要的同意和授权；</span><span
              lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:17.85pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:17.85pt;text-indent:-17.85pt;
mso-list:l1 level1 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>在访问和使用服务时，始终提交准确、完整和最新的信息；</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:17.85pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:17.85pt;text-indent:-17.85pt;
mso-list:l1 level1 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span
              style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>维持开展用户业务所需的所有执照和许可，如果任何执照或许可受到（或可能受到）惩罚或被撤销、终止或以其它方式受到限制，应立即通知百士吉；以及</span><span
              lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:17.85pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:17.85pt;text-indent:-17.85pt;
mso-list:l1 level1 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>保存用户遵守本协议规定义务的完整和准确记录。在用户使用服务期间及之后<span
                class=GramE>一</span>（</span><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'>1</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>）年期间，百<span
                class=GramE>士吉应</span>根据其合理要求，获得与用户使用程序相关的所有用户记录、文档、文件和清单的访问权，以进行稽查，并确定用户是否遵守本协议。</span><span lang=EN-US
              style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:17.85pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:17.85pt;background:white'><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>3.5<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>用户赔偿</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。<a name="_Hlk97129704"></a><span class=GramE><span
                  style='mso-bookmark:_Hlk97129704'>若百士吉及其</span></span><span style='mso-bookmark:
_Hlk97129704'>管理人员、董事、雇员和代理人（以下统称“<u>百士吉受偿方”</u>）直接或间接、全部或部分因以下原因遭受以下任何类型的索赔、责任、损失、费用、损害和</span></span><span
              style='mso-bookmark:_Hlk97129704'><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'>/</span></span><span
              style='mso-bookmark:_Hlk97129704'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>或支出</span></span><span style='mso-bookmark:_Hlk97129704'><span
                lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'>(</span></span><span
              style='mso-bookmark:_Hlk97129704'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>包括且不限于合理的律师费</span></span><span
              style='mso-bookmark:_Hlk97129704'><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'>)</span></span><span
              style='mso-bookmark:_Hlk97129704'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>：</span></span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>（</span><span class=SpellE><span lang=EN-US style='font-size:
10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'>i</span></span><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>）用户不遵守法律；以及（</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>ii</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>）用户使用服务<a name="_Hlk97129765">，</a><a name="_Hlk97129853"><span
                  style='mso-bookmark:_Hlk97129765'>用户在此不可撤销并且无条件地同意，在法律允许的最大范围内，</span></a>为<span
                class=GramE>百士吉受偿</span>方抗辩、向其做出赔偿，并使其免受损害。<a name="_Hlk97130093">本条款应在本协议终止或到期后仍然有效</a>。</span><span
              lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal><b style='mso-bidi-font-weight:normal'><u><span lang=EN-US style='font-size:10.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:#212529'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l4 level1 lfo1;background:white'>
            <![if !supportLists]><b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:"Times New Roman";color:#212529'><span style='mso-list:Ignore'>4.<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span></span></b>
            <![endif]><b style='mso-bidi-font-weight:normal'><u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>服务水平和免责声明</span></u></b><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>。百<span class=GramE>士吉应</span>根据其当时的服务水平协议提供服务。<a
                name="_Hlk97130144">除上述情况外，服务是按“原样”和“可用状态”提供</a>。<span class=GramE>百士吉对</span>服务不作任何<a
                name="_Hlk97130154">形式</a>（明示、暗示或法定）的陈述或保证，包括但不限于对<a name="_Hlk97130177">某一</a>特定用途的适用性、准确性、适销性或<span
                class=GramE>不</span>侵权的任何保证。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'><span style='mso-spacerun:yes'>&nbsp; </span></span><span class=GramE><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:
"Times New Roman";mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:
"Times New Roman";color:#212529'>百士吉不</span></span><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>保证服务在运行时不会中断或无错误。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;background:white'><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l4 level1 lfo1;background:white'><a name="_Hlk97130199">
              <![if !supportLists]><b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:"Times New Roman";color:#212529'><span style='mso-list:Ignore'>5.<span
                      style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span></span></b>
              <![endif]><b style='mso-bidi-font-weight:normal'><u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>知识产权</span></u></b>
            </a><span style='mso-bookmark:_Hlk97130199'></span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;background:white'><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal;background:
white'><a name="_Hlk97130211"><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:black;mso-color-alt:windowtext'>5.1</span></a><span
              style='mso-bookmark:_Hlk97130211'><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#333333'> </span></span><span style='mso-bookmark:_Hlk97130211'><span
                class=GramE><span style='font-size:
10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:
"Times New Roman";color:#333333'>百士吉</span></span></span><span style='mso-bookmark:_Hlk97130211'><span style='font-size:10.0pt;font-family:
"Times New Roman",serif;color:#333333'> </span></span><span style='mso-bookmark:
_Hlk97130211'><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:
"Times New Roman";mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#333333'>提供的服务中包含的任何文本、图片、图形、音频和</span></span><span
              style='mso-bookmark:_Hlk97130211'><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#333333'>/</span></span><span style='mso-bookmark:_Hlk97130211'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#333333'>或视频资料均受著作权、商标、专利和</span></span><span style='mso-bookmark:_Hlk97130211'><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#333333'>/</span></span><span style='mso-bookmark:_Hlk97130211'><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#333333'>或其它知识产的保护。未经<span
                  class=GramE>百士吉的</span>事先书面同意，上述资料均不得用于任何商业目的。</span></span><span
              style='mso-bookmark:_Hlk97130211'><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#333333'></span></span></p>

          <p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span style='mso-bookmark:_Hlk97130211'><span
                lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#333333'><br>
              </span></span><span style='mso-bookmark:_Hlk97130211'><span lang=EN-US
                style='font-size:10.0pt;font-family:"Times New Roman",serif'>5.2 </span></span><span
              style='mso-bookmark:_Hlk97130211'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#333333'>未经<span class=GramE>百士吉的</span>书面许可，用户不得对</span></span><span style='mso-bookmark:_Hlk97130211'><span
                class=GramE><span style='font-size:
10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:
"Times New Roman"'>百士吉<span style='color:#333333'>为</span></span></span></span><span
              style='mso-bookmark:_Hlk97130211'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#333333'>提供服务而使用的任何软件（包括但不限于软件中所含的任何<span
                  class=GramE>图象</span>、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料）进行反向工程（</span></span><span
              style='mso-bookmark:_Hlk97130211'><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#333333'>reverse engineer</span></span><span
              style='mso-bookmark:_Hlk97130211'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#333333'>）、反向编译（</span></span><span style='mso-bookmark:_Hlk97130211'><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#333333'>decompile</span></span><span style='mso-bookmark:_Hlk97130211'><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#333333'>）或反汇编（</span></span><span style='mso-bookmark:_Hlk97130211'><span
                lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#333333'>disassemble</span></span><span
              style='mso-bookmark:_Hlk97130211'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#333333'>）。</span></span><span style='mso-bookmark:_Hlk97130211'><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#333333'></span></span></p>

          <span style='mso-bookmark:_Hlk97130211'></span>

          <p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span lang=EN-US
              style='font-size:10.0pt'></span></p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l4 level1 lfo1;background:white'>
            <![if !supportLists]><b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:"Times New Roman";color:#212529'><span style='mso-list:Ignore'>6.<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span></span></b>
            <![endif]><b style='mso-bidi-font-weight:normal'><u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>责任限制</span></u></b><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>。<a name="_Hlk97130386">在任何情况下，</a><span class=GramE><span
                  style='mso-bookmark:_Hlk97130386'>百士吉对</span></span><span
                style='mso-bookmark:_Hlk97130386'>任何偶然性、惩罚性、惩戒性、特殊性、信赖性、间接性损害（包括但不限于因数据丢失或损坏导致的损害、利润或商誉损失、业务中断或干扰、无法使用服务或干扰使用服务）概不负责，无论其是否被告知此类损害的可能性。在任何情况下，<span
                  class=GramE>百士吉在</span>本协议项下或与服务相关的赔偿责任均不超过</span>（</span><span class=SpellE><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>i</span></span><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>）</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>100</span><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>美元或（</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>ii</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>）<a name="_Hlk97130416">产生索赔之前紧接的六</a></span><span
              style='mso-bookmark:_Hlk97130416'><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'>(6)</span></span><span
              style='mso-bookmark:_Hlk97130416'><span class=GramE><span style='font-size:
10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>个</span></span></span><span
              style='mso-bookmark:_Hlk97130416'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>月内</span></span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>用户使用服务而向<span class=GramE>百士吉所</span>支付的金额，以较大者为准。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;background:white'><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l4 level1 lfo1;background:white'>
            <![if !supportLists]><b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:"Times New Roman";color:#212529'><span style='mso-list:Ignore'>7.<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span></span></b>
            <![endif]><b style='mso-bidi-font-weight:normal'><u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>期限；终止。</span></u></b><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>7.1<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>百<span class=GramE>士吉提出</span>终止</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。百<span class=GramE>士吉可</span>随时向用户发出通知终止本协议，无论是否说明原因。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>7.2<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>服务终止的修改</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。如果百<span class=GramE>士吉停止</span>提供服务，百<span class=GramE>士吉可</span>随时终止本协议。</span><span lang=EN-US
              style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>7.3<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>终止效力</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。在本协议因任何原因终止后，用户使用服务的权利应立即终止。本协议终止不应解除任何一方在终止日期前累积的任何义务。协议终止不应影响双方在第</span><u><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>1.3</span></u><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>、</span><u><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>2.3</span></u><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>、</span><u><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>3.4</span></u><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>、</span><u><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>3.5</span></u><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>、</span><u><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>4</span></u><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>、</span><u><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>6</span></u><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>、</span><u><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>7.3</span></u><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>、</span><u><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>8</span></u><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>、</span><u><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>9</span></u><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>条下的义务。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'><span style='mso-spacerun:yes'>&nbsp; </span></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;background:white'><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l4 level1 lfo1;background:white'>
            <![if !supportLists]><b><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>8.<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span></span></b>
            <![endif]><b style='mso-bidi-font-weight:normal'><u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>机密信息</span></u></b><b><u><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'></span></u></b>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>8.1<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>定义。</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>“<u>机密信息</u>是指服务、其相关文件、百士吉对服务的定价，以及百士吉根据本协议披露的被指定为机密或根据其性质将被合理预期为机密的其它信息。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>8.2<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>排除</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。尽管有上一段的规定，但<span class=GramE>百士吉的</span>机密信息不应包括以下信息：（</span><span class=SpellE><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:
等线;mso-fareast-theme-font:minor-fareast;color:#212529'>i</span></span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>）通过用户的任何作为或不作为而可公开获得的信息；或（</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'>ii</span><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>）在披露前由用户合法拥有，且用户未直接或间接从百<span
                class=GramE>士吉获得</span>的信息；或（</span><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'>iii</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>）由不受保密义务约束的第三方合法披露给用户；或（</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'>iv</span><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>）由用户在<span class=GramE>未访问</span>或使用机密信息的情况下独立开发的信息。</span><span
              lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>8.3<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>禁止披露</span></u><span
              style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。用户同意对机密信息保密。用户同意不向任何第三方提供任何形式的机密信息，也不将机密信息用于履行其在本协议项下的义务或享有其在本协议项下的权利以外的任何目的。用户同意使用与保护其自身类似性质和价值的机密信息相同的谨慎程度，但在任何情况下都不得低于合理的谨慎标准，以确保其员工或代理人不会违反本协议的规定披露或发布机密信息。用户声明，其与可能有权访问任何机密信息的每一位员工签订了一份适当的协议，足以使其遵守<u>第</u></span><u><span
                lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>8</span></u><u><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>条</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>的所有条款。</span><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'><span style='mso-spacerun:yes'>&nbsp;
              </span></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>8.4<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>需要的披露</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。尽管有上述规定，用户仍可在法律或法院命令要求披露的情况下披露机密信息，<u>但前提是</u>用户事先向百<span
                class=GramE>士吉发出</span>此类披露的书面通知，并在获得保护机密信息不被公开披露的命令方面提供合理的协助。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>8.5<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>终止</span></u><u><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>/</span></u><u><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>到期</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。本协议终止或到期后，用户应将用户拥有或控制的任何机密信息<span class=GramE>归还给百士</span>吉。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;background:white'><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l4 level1 lfo1;background:white'>
            <![if !supportLists]><b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:"Times New Roman";color:#212529'><span style='mso-list:Ignore'>9.<span
                    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span></span></b>
            <![endif]><b style='mso-bidi-font-weight:normal'><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>其它规定</span></b><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>9.1<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>双方之间的关系</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。本协议不得解释为在双方之间建立任何代理、合伙、合资或其它类似的法律关系；任何一方也不会自称为另一方的代理人、合伙人或合资方。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>9.2<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>遵守法律</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。各方在履行本协议时应遵守中国政府机构或机关的所有适用法律和法规。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>9.3<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>通知</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。当需要在本协议项下向百<span
                class=GramE>士吉发出</span>通知时，该通知应采用书面形式，并发送至【百士吉泵业（天津）有限公司】地址：【天津市华苑产业区（环外）海泰华科二路</span><span lang=EN-US
              style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>2</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>号】收件人：</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>[Eric Feng]</span><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>。<span
                class=GramE>百士吉可以</span>向用户发出需要的通知，通知发送至用户提供的与该用户</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'>ID</span><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>相关的电子邮箱。</span><span lang=EN-US
              style='font-size:10.0pt;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>9.4<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>放弃</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。行为或未能执行权利不应被视为放弃。任何放弃<u>只有</u>经双方书面签字才有效。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>9.5<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>可分割性</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。如果本协议的任何条款被认定为无效、作废或不可执行，该条款应被视为根据适用法律尽可能反映双方的原始意图，本协议的其余条款应仍然充分有效。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>9.6<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>转让</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。未经<span
                class=GramE>百士吉事先</span>书面同意，用户不得转让或委托其在本协议项下的任何权利、利益或义务，无论是通过法律还是其它方式。通过单笔交易或一系列交易出售用户的控股权应被视为本协议项下的转让，需要<span
                class=GramE>百士吉的</span>同意。如果发生合并、收购或其它控制权变更，<span
                class=GramE>百士吉可以</span>将本协议转让给继任者。本协议应对双方及其各自的继承人和许可受让人具有约束力，并以他们为受益人。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>9.7<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>适用法律</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。本协议以及与本协议相关的所有索赔、本协议的执行或双方在本协议项下的履行，在所有方面均应受中国法律的约束，并按该法律进行解释，而不考虑其中的法律冲突条款。</span><span lang=EN-US
              style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>9.8<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>争议解决</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。因本协议引起的或与本协议有关的任何争议，</span><span
              style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#333333'>各方应友好协商解决；协商不成的，任何一方均应将有关争议提交至上海国际经济贸易仲裁委员会并按照其届时有效的仲裁规则仲裁。仲裁裁决是终局的，对各方均有约束力。</span><span
              lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>9.9<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>不可抗力</span></u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>。如果由于任何天灾、任何政府或任何政府机构、战争、叛乱、恐怖主义行为、自然因素、罢工或劳资纠纷、或百士吉无法控制的其它类似或不同的原因导致延迟或未能履行本协议，<span
                class=GramE>百士吉不</span>承担违约或其它责任。用户承认，履行某些百<span class=GramE>士吉义务</span>可能需要用户指定的、百<span
                class=GramE>士吉控制</span>之外的第三方的合作。如果该第三方未能以合理允许百<span class=GramE>士吉履行</span>其义务的方式与<span
                class=GramE>百士吉合作</span>，就本条而言，此类不作为应被视为百士吉无法控制的原因，且不应作为确定百<span
                class=GramE>士吉违反</span>其在本协议项下的任何义务或承担其它责任的依据。如果任何此类情况持续三十（</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>30</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>）天，并阻止另一方继续履行本协议，任何一方均可自行选择终止本协议。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:6.0pt;margin-right:0cm;margin-bottom:
6.0pt;margin-left:1.0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:1.0cm;text-indent:-1.0cm;
mso-list:l4 level2 lfo1;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:11.0pt;mso-bidi-font-size:10.0pt;mso-fareast-font-family:
"Times New Roman";color:#212529'><span style='mso-list:Ignore'>9.10<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
            <![endif]><u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>完整协议</span></u><span
              style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>。本协议应构成双方之间关于用户访问和使用服务的完整协议，并取代之前与本协议标的相关的所有书面或口头协议或陈述。<u>除非</u>各方正式授权代表签署书面文件，否则不得修改或修订本协议。双方明确同意，任何采购订单或用户类似文书的任何条款和条件若可能存在冲突，应被本协议的条款和条件所取代。</span><span
              lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.0pt;
line-height:107%;font-family:"Times New Roman",serif;mso-fareast-font-family:
等线;mso-fareast-theme-font:minor-fareast;color:#212529;mso-ansi-language:EN-US;
mso-fareast-language:ZH-CN;mso-bidi-language:AR-SA'><br clear=all
                style='mso-special-character:line-break;page-break-before:always'>
            </span></b>

          <p class=MsoNormal><b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:#212529'></span></b></p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;line-height:normal;mso-outline-level:1;
background:white'><b style='mso-bidi-font-weight:normal'><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>附件</span></b><b style='mso-bidi-font-weight:normal'><span
                lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'>A</span></b><b style='mso-bidi-font-weight:normal'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>：隐私政策</span></b><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529;mso-font-kerning:18.0pt'></span></p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;line-height:normal;background:white'><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'><span style='mso-spacerun:yes'>&nbsp;</span></span></p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;line-height:normal;background:white'><i style='mso-bidi-font-style:normal'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>生效日期：</span></i><i style='mso-bidi-font-style:normal'><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'>2022</span></i><i style='mso-bidi-font-style:normal'><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>年</span></i><i style='mso-bidi-font-style:normal'><span
                lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'>7</span></i><i style='mso-bidi-font-style:normal'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>月</span></i><i style='mso-bidi-font-style:normal'><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'>1</span></i><i style='mso-bidi-font-style:normal'><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>日</span></i><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;line-height:normal;background:white'><span class=GramE><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:
"Times New Roman";mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#212529'>百士吉泵业</span></span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>（天津）有限公司（以下简称“百士吉”）尊重您的个人隐私。本隐私政策使您能够控制您的个人信息在<span
                class=GramE>百士吉的</span>使用方式，包括与我们的网站</span><span lang=EN-US style='font-size:
10.0pt;font-family:"Times New Roman",serif;color:#212529'>www.hydroconnect.hydrosystemschina.com</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>、</span><span lang=EN-US
              style='font-size:10.0pt;font-family:"Times New Roman",serif;color:#212529'>www.hydrosystemschina.com</span><span
              style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>和</span><span lang=EN-US
              style='font-size:10.0pt;font-family:"Times New Roman",serif;color:#212529'>www.psgdover.com.cn</span><span
              style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>（以下统称“百士吉网站”）的连接。本隐私政策描述了我们如何收集、使用和共享在线下和线上获得的个人信息。</span><span
              lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'><span style='mso-spacerun:yes'>&nbsp; </span></span></p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;line-height:normal;background:white'><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>如果您需要获得本隐私政策的副本，请通过</span><span lang=EN-US
              style='color:black;mso-color-alt:windowtext'><a href="mailto:PSG-China@psgdover.com"><span
                  style='mso-bidi-font-family:"Times New Roman"'>PSG-China@psgdover.com</span></a></span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>联系我们，或者拨打</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'>400 600 4026</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>。</span><span lang=EN-US style='font-size:10.0pt;font-family:
"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;line-height:normal;background:white'><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>如果您是<span
                class=GramE>百士吉或百士吉关联</span>公司的员工，请参阅雇主提供的《员工隐私声明》。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;line-height:normal;background:white'><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>本隐私政策由</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'>https://www.hydrosystemschina.com</span><span style='font-size:
10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:
"Times New Roman";color:#212529'>和</span><span lang=EN-US style='font-size:
10.0pt;font-family:"Times New Roman",serif;color:#212529'>https://www.psgdover.com.cn/</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>上的使用条款补充。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;line-height:normal;background:white'><u><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>目录</span></u><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l1 level2 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>1.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>我们是谁以及联系方式</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l1 level2 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>2.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>我们收集什么个人信息</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l1 level2 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>3.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>我们如何处理收集到的个人信息</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l1 level2 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>4.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>市场营销</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l1 level2 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>5.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>数据共享</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l1 level2 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>6.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>法律基础</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l1 level2 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>7.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>安全与保密</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l1 level2 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>8.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>您个人信息的保存期限</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l1 level2 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>9.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>个人信息转移</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l1 level2 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>链接到其它网站</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l1 level2 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>与您个人信息相关的权利</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <span lang=EN-US style='font-size:10.0pt;line-height:107%;font-family:"Times New Roman",serif;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529;
mso-ansi-language:EN-US;mso-fareast-language:ZH-CN;mso-bidi-language:AR-SA'><br clear=all
              style='mso-special-character:line-break;page-break-before:always'>
          </span>

          <p class=MsoNormal><span lang=EN-US style='font-size:10.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l1 level2 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>未成年人</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l1 level2 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'>Cookies</span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.25pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.25pt;text-indent:-21.25pt;
mso-list:l1 level2 lfo2;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>有什么问题吗？</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;line-height:normal;background:white'><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'><span style='mso-spacerun:yes'>&nbsp;</span></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:17.85pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:17.85pt;text-indent:-17.85pt;
mso-list:l2 level1 lfo3;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>1.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>我们是谁以及联系方式</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>本网站为：</span><span lang=EN-US style='font-size:10.0pt;font-family:
"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span class=GramE><span style='font-size:
10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:
"Times New Roman";color:#212529'>百士吉泵业</span></span><span style='font-size:
10.0pt;font-family:"Times New Roman",serif;color:#212529'> </span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>上海办公室</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>地址：上海市徐汇区桂平路</span><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'>391</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>号，</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>新漕河<span class=GramE>泾</span>国际商务中心</span><span lang=EN-US
              style='font-size:10.0pt;font-family:"Times New Roman",serif;color:#212529'>A</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>座</span><span lang=EN-US
              style='font-size:10.0pt;font-family:"Times New Roman",serif;color:#212529'>18</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>楼，</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'>200233</span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span class=GramE><span style='font-size:
10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:
"Times New Roman";color:#212529'>百士吉泵业</span></span><span style='font-size:
10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:
"Times New Roman";color:#212529'>（天津）有限公司</span><span lang=EN-US
              style='font-size:10.0pt;font-family:"Times New Roman",serif;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>地址：天津市华苑产业区（环外）海泰华科二路</span><span lang=EN-US style='font-size:
10.0pt;font-family:"Times New Roman",serif;color:#212529'>2</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>号，</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'>300384 </span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>您可以通过电子邮件联系我们：</span><span lang=EN-US style='color:black;
mso-color-alt:windowtext'><a href="mailto:PSG-China@psgdover.com"><span
                  style='font-size:10.0pt;font-family:"Times New Roman",serif'>PSG-China@psgdover.com</span></a></span><span
              style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>，或可以致电</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'>400 600 4026</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>。</span><span lang=EN-US style='font-size:10.0pt;font-family:
"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:17.85pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:17.85pt;text-indent:-17.85pt;
mso-list:l2 level1 lfo3;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>2.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>我们收集什么个人信息</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>我们是一家面向企业的公司，根据业务联系人与<span class=GramE>百士吉的</span>互动方式（包括通过<span class=GramE>百士吉网站</span>或任何其它<span
                class=GramE>百士吉在线</span>服务）收集以下信息：</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:36.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:36.0pt;text-indent:-36.0pt;
mso-list:l5 level1 lfo4;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(i)<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>联系信息，包括姓名、电子邮件地址、邮寄地址、电话号码和有关您询问的信息等；</span><span lang=EN-US
              style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:36.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:36.0pt;text-indent:-36.0pt;
mso-list:l5 level1 lfo4;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(ii)<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>在线标识符，例如关于您用来访问<span
                class=GramE>百士吉网站</span>的设备的信息（例如，</span><span lang=EN-US style='font-size:
10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'>IP</span><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>地址），以及关于您如何与我们的网站和服务互动的信息。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:36.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:36.0pt;background:white'><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:17.85pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:17.85pt;text-indent:-17.85pt;
mso-list:l2 level1 lfo3;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>3.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>我们如何处理收集到的个人信息</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>我们将收集到的个人信息用于以下目的：</span><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:36.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:36.0pt;text-indent:-36.0pt;
mso-list:l6 level1 lfo5;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(i)<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>了解您的需求，为您提供更好的服务，并答复您的询问；</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:36.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:36.0pt;text-indent:-36.0pt;
mso-list:l6 level1 lfo5;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(ii)<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>在遵守以下规定的前提下，我们还将您的个人信息用于其它与营销有关的目的；</span><span lang=EN-US
              style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:36.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:36.0pt;text-indent:-36.0pt;
mso-list:l6 level1 lfo5;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(iii)<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>改善<span class=GramE>百士吉网站</span>提供的产品和服务：</span><span lang=EN-US
              style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:36.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:36.0pt;text-indent:-36.0pt;
mso-list:l6 level1 lfo5;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(iv)<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>统计<span class=GramE>百士吉</span></span><span style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'> </span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:
"Times New Roman";mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:
"Times New Roman";color:#212529'>网站的访客数量以及访客最感兴趣的网站区域，并定制<span class=GramE>百士吉</span></span><span style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'> </span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:
"Times New Roman";mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:
"Times New Roman";color:#212529'>网站；以及</span><span lang=EN-US style='font-size:
10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'></span>
          </p>

          <p class=MsoNormal><span style='font-size:10.0pt;line-height:107%;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>确定向谁和何处发送</span><span lang=EN-US style='font-size:10.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:#212529'>Hydro
              Connect</span><span style='font-size:10.0pt;line-height:107%;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>通信，如移动警报、<span class=GramE>帐户</span>信息和系统停机通知。</span><span lang=EN-US style='font-size:10.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>我们不会使用您通过<span class=GramE>百士吉网站</span>提供的个人信息，来做出与<span class=GramE>您相关</span>的自动决策。</span><span
              lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>在适用法律允许的范围内，<span class=GramE>百士吉也会</span>在我们认为必要或适当的情况下使用您的个人信息：</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:17.85pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:17.85pt;text-indent:-17.85pt;
mso-list:l0 level1 lfo6;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>根据适用法律，包括您居住国家</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>/</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>地区以外的法律；</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:17.85pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:17.85pt;text-indent:-17.85pt;
mso-list:l0 level1 lfo6;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>遵守法律程序；</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:17.85pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:17.85pt;text-indent:-17.85pt;
mso-list:l0 level1 lfo6;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span class=GramE><span style='font-size:10.0pt;
font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";color:#212529'>应您居住</span></span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>国以外的公共和政府机构（包括公共和政府机构）的要求；</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:17.85pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:17.85pt;text-indent:-17.85pt;
mso-list:l0 level1 lfo6;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>执行我们的条款和条件；</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:17.85pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:17.85pt;text-indent:-17.85pt;
mso-list:l0 level1 lfo6;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>保护我们或我们任何关联公司的运营；</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:17.85pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:17.85pt;text-indent:-17.85pt;
mso-list:l0 level1 lfo6;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>保护我们的权利、隐私、安全或财产，和</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>/</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>或我们的分支机构、您或其他人的权利、隐私、安全或财产，以及</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:17.85pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:17.85pt;text-indent:-17.85pt;
mso-list:l0 level1 lfo6;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>(g)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>允许我们寻求可用的补救措施或限制我们可能遭受的损失。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:17.85pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:17.85pt;text-indent:-17.85pt;
mso-list:l2 level1 lfo3;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>4.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>市场营销</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>在当地法律要求的情况下，我们将获得您的事先同意：</span><span lang=EN-US
              style='font-size:10.0pt;font-family:"Times New Roman",serif;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.7pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.7pt;text-indent:-18.7pt;
mso-list:l3 level1 lfo7;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>a.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>为了向您发送有关<span class=GramE>百士吉的</span>营销信息；</span><span
              lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.7pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.7pt;text-indent:-18.7pt;
mso-list:l3 level1 lfo7;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>b.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>为了营销目的向<span class=GramE>百士吉关联</span>公司提供您的个人信息；</span><span
              lang=EN-US style='font-size:
10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
background:white'><span lang=EN-US style='font-size:10.0pt;line-height:107%;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
background:white'><span style='font-size:10.0pt;line-height:107%;font-family:
等线;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin;
color:#212529'>请注意，您始终有权撤回您的同意。</span><span lang=EN-US style='font-size:10.0pt;
line-height:107%;color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>除非您提供相关指示，否则我们可能会通过电子邮件、电话、传真、信件或其他其它方式与您联系。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>我们可能将您的个人信息用于我们自有营销目的，包括但不限于通过信件、电子邮件、电话、短信和其它方式向您通知新服务、优惠或其它信息。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>如果您不希望我们将您的个人信息用于营销目的，或将您的个人信息提供<span
                class=GramE>给百士吉</span>关联公司或第三方用于营销目的，则可以通过下方“您的个人信息权利”标题下的程序通知我们，选择退出此类用途。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal><span lang=EN-US style='font-size:10.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.0pt;text-indent:-18.0pt;
mso-list:l2 level1 lfo3;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>5.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>数据共享</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.0pt;background:white'><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>我们可能会根据上文第</span><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'>3</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>节中规定的商业目的与我们的关联公司（即</span><span lang=EN-US style='font-size:
10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'>DOVER</span><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>的关联企业和运营公司）（统称为“百士吉关联企业”）共享您的个人信息。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.0pt;background:white'><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>我们可能会与第三<span class=GramE>方服务</span>提供商（包括向我们提供服务的承包商）共享您的个人信息，以协助上述个人信息的任何使用。例如，我们可能会与</span><span
              lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>/</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>或向协助<span
                class=GramE>百士吉网站</span>运营、管理或维护，或进行入职前筛选的第三方共享和</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>/</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>或提供您的个人信息。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>我们也可能向第三方披露您的个人信息，前提是：适用法律或法规要求披露；遵守法律程序或法院命令；应公共和政府部门的要求；或为执行我们的隐私政策和</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'>/</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:
"Times New Roman";mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#212529'>或使用条款而必须进行披露；保护您的安全；保护我们的权利，我们的网站和财产或<span
                class=GramE>百士吉关联</span>公司或第三方的财产的安全；允许我们采取补救措施或限制我们可能遭受的损失。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>我们不会将个人信息出售给第三方。但是，如果<span
                class=GramE>百士吉进行</span>或参与诸如合并、收购、重组或出售其部分或全部资产的商业交易，我们可能会将您的个人信息共享或转移给第三方。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.0pt;text-indent:-18.0pt;
mso-list:l2 level1 lfo3;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>6.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>法律基础</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>根据中国法律，我们必须公示使用您个人信息的法律基础。包括：</span><span lang=EN-US
              style='font-size:10.0pt;font-family:"Times New Roman",serif;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>合法权益――通过您提供的详细信息与您联系，以管理我们与您的关系以及监测和改进<span
                class=GramE>百士吉网站</span>的使用并向您发送营销通讯，在这些方面，我们拥有合法权益。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>同意――当地法律要求我们获得您的同意，以便向您发送营销信息</span><span lang=EN-US
              style='font-size:10.0pt;font-family:"Times New Roman",serif;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>遵守法律义务――为履行法律或监管义务而收集和披露您的个人信息</span><span lang=EN-US
              style='font-size:10.0pt;font-family:"Times New Roman",serif;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>我们不会通过<span class=GramE>百士吉网站</span>收集<a name="_Hlk102746646">中国法律规定的</a>任何敏感的个人信息。</span><span
              lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>我们基于合法权益使用您的个人信息时，您始终有权通过上述方式联系我们来反对此类使用。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>我们基于您的同意使用您的个人信息时，您始终有权随时通过上述方式联系我们来撤回您的同意。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal><span lang=EN-US style='font-size:10.0pt;line-height:107%;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.0pt;text-indent:-18.0pt;
mso-list:l2 level1 lfo3;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>7.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>安全与保密</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span
              style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>我们竭力确保您个人信息的安全。为了防止未经授权的访问或披露，我们已经制定了适合于信息性质的合理的安全程序和惯例，包括物理、电子和管理程序，以保护我们在线收集的个人信息。但是，我们无法保证您自愿提供给我们的非机密数据（例如反馈、问题、评论或想法）的机密性（例如通过“联系”标签或<span
                class=GramE>百士吉网站</span>上的类似功能）。我们保留根据适用法律使用和披露我们认为适当的非机密信息的权利。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.0pt;text-indent:-18.0pt;
mso-list:l2 level1 lfo3;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>8.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>您个人信息的保存期限</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>您个人信息的保存期限为：</span><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:39.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:39.0pt;text-indent:-18.0pt;
mso-list:l0 level2 lfo6;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>・<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>我们与<span class=GramE>您存在</span>业务关系；</span><span lang=EN-US
              style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:39.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:39.0pt;text-indent:-18.0pt;
mso-list:l0 level2 lfo6;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>・<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>法律要求我们保存</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>;</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>或</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:39.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:39.0pt;text-indent:-18.0pt;
mso-list:l0 level2 lfo6;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>・<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>为保护业务或<span class=GramE>其他第三</span>方，我们需要<span
                class=GramE>保存您</span>的个人数据。</span><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>如果您对我们的保存政策有任何疑问请使用上述方式联系我们。</span><span lang=EN-US
              style='font-size:10.0pt;font-family:"Times New Roman",serif;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.0pt;text-indent:-18.0pt;
mso-list:l2 level1 lfo3;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>9.<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>个人信息转移</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>作为一家全球性企业，我们可能会将您的个人信息传输到国外。</span><span style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'> </span><span class=GramE><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>百士吉关联</span></span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>公司位于世界各地，但<span
                class=GramE>百士吉的</span>总部位于美国。如果需要跨境传输个人信息以遵守适用法律，我们将实施适当的保护措施来保护该个人信息。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'><span style='mso-spacerun:yes'>&nbsp; </span></span><span
              style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>在跨境传输之前，我们会始终确保接收人具备令我们合理满意的、适当水平的数据保护措施，我们还将确保进行安全评估并满足适用法律规定的任何其它要求。如果您需要更多信息，请使用上述详细信息与我们联系。</span><span
              style='font-size:10.0pt;font-family:"Times New Roman",serif;color:#212529'> <span lang=EN-US></span></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.0pt;text-indent:-18.0pt;
mso-list:l2 level1 lfo3;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>其它网站的链接</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span class=GramE><span style='font-size:
10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:
"Times New Roman";color:#212529'>百士吉网站</span></span><span style='font-size:
10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:
"Times New Roman";color:#212529'>可能包含链接，帮助您<span
                class=GramE>轻松访问您</span>感兴趣的其它网站。但是，您使用这些链接离开我们的网站后，您应该注意：我们对该等其它网站没有任何控制权。因此，对于您在访问此类网站时提供的任何个人信息的保护和隐私，我们不承担任何责任，并且此类网站不受本隐私政策的约束。您应谨慎行事，并认真阅读适用于所述网站的隐私声明。</span><span
              lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.0pt;text-indent:-18.0pt;
mso-list:l2 level1 lfo3;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>与您个人信息相关的权利</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>根据特定法律，您有权请求获得相关信息或访问、更正或<span
                class=GramE>删除您</span>的个人信息，限制对个人信息的某些使用或披露，以及反对我们使用或请求数据迁移。如果您希望行使其中的权利，请与我们联系。</span><span lang=EN-US
              style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal><span style='font-size:10.0pt;line-height:107%;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>在任何情况下，您都可以要求我们提供有关能够查看和<span class=GramE>访问您</span>个人信息的人员的更多信息。</span><span lang=EN-US style='font-size:10.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>您可以通过发送电子邮件至</span><span lang=EN-US style='color:black;
mso-color-alt:windowtext'><a href="mailto:PSG-China@psgdover.com"><span
                  style='font-size:10.0pt;font-family:"Times New Roman",serif'>PSG-China@psgdover.com</span></a></span><span
              style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>来限制我们收集或使用您的个人信息。这包括您：</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.3pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.3pt;text-indent:-21.3pt;
mso-list:l0 level2 lfo6;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>・<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>以前不反对我们将您的个人信息用于直接营销目的，但现在希望停止接收我们的营销电子邮件；</span><span
              lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.3pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.3pt;text-indent:-21.3pt;
mso-list:l0 level2 lfo6;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>・<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>我们的信息政策发生变化后</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'>,</span><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>希望拒绝本隐私政策允许的对个人信息的任何新使用；</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.3pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.3pt;text-indent:-21.3pt;
mso-list:l0 level2 lfo6;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>・<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>不希望我们出于营销目的与<span class=GramE>百士吉关联</span>公司和</span><span
              lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'>/</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
color:#212529'>或我们的业务（和其他）合作伙伴共享您的个人信息；</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;
color:#212529'></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.3pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.3pt;text-indent:-21.3pt;
mso-list:l0 level2 lfo6;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>・<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span
              style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>认为我们持有的您的个人信息不正确或不完整，鉴于您的担忧，在适当的时候，我们将及时更正发现不正确的任何个人信息。或者，如果您希望通过使用我们主页上的“联系”标签来查看或<span
                class=GramE>更改您</span>的个人信息，则可以与我们联系；以及</span><span style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'>
              <span lang=EN-US></span></span>
          </p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:21.3pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:21.3pt;text-indent:-21.3pt;
mso-list:l0 level2 lfo6;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>・<span
                  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>不希望我们在将来与您联系（您主动联系的除外）。</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>您也可以通过上述地址（位于本隐私政策的开头）给我们致函，或致电</span><span lang=EN-US
              style='font-size:10.0pt;font-family:"Times New Roman",serif;color:#212529'>400
              600 4026</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:
"Times New Roman";mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#212529'>与我们联系。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>我们可能不时希望将您通过<span class=GramE>百士吉网站</span>收集的个人信息用于新的用途。如果新目的与上述目的不一致，我们将采取合理措施通知您新目的。</span><span
              lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.0pt;text-indent:-18.0pt;
mso-list:l2 level1 lfo3;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>未成年人</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>我们不会有意收集或允许收集未成年人的个人信息。如果您是未成年人的父母或监护人，并且认为该未成年人已向我们提供了个人信息，请通过</span><span lang=EN-US
              style='color:black;mso-color-alt:windowtext'><a href="mailto:PSG-China@psgdover.com"><span style='font-size:10.0pt;font-family:
"Times New Roman",serif'>PSG-China@psgdover.com</span></a></span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>我们联系。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.0pt;text-indent:-18.0pt;
mso-list:l2 level1 lfo3;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span lang=EN-US style='font-size:10.0pt;
mso-fareast-font-family:等线;mso-fareast-theme-font:minor-fareast;color:#212529'>Cookies</span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>当您访问<span class=GramE>百士吉网站</span>时，我们可能会使用某些功能性“</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'>cookies</span><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>”和类似技术来帮助我们识别并更好地为您服务。</span><span lang=EN-US style='font-size:
10.0pt;font-family:"Times New Roman",serif;color:#212529'>Cookies</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>是小型文件，包含网站发送的信息，该信息保存在计算机的硬盘驱动器中。您可以从计算机中删除</span><span
              lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'>cookie</span><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>，或将浏览器设置为拒绝</span><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'>cookie</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>。但是，这样做可能会限制我们网站的某些功能。请在<span
                class=GramE>百士吉网站</span>上查看我们的</span><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'>Cookies</span><span style='font-size:10.0pt;font-family:等线;mso-ascii-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#212529'>政策，以获取有关我们使用这些技术的更多信息。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span lang=EN-US style='font-size:10.0pt;
font-family:"Times New Roman",serif;color:#212529'></span></p>

          <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
6.0pt;margin-left:18.0pt;mso-para-margin-top:0cm;mso-para-margin-right:0cm;
mso-para-margin-bottom:.5gd;mso-para-margin-left:18.0pt;text-indent:-18.0pt;
mso-list:l2 level1 lfo3;background:white'>
            <![if !supportLists]><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
color:#212529'><span style='mso-list:Ignore'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
                </span></span></span>
            <![endif]><span style='font-size:10.0pt;font-family:等线;
mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";color:#212529'>有什么问题吗？</span><span lang=EN-US style='font-size:10.0pt;mso-fareast-font-family:等线;mso-fareast-theme-font:
minor-fareast;color:#212529'></span>
          </p>

          <p class=MsoNormal style='margin-bottom:6.0pt;mso-para-margin-bottom:.5gd;
line-height:normal;background:white'><span style='font-size:10.0pt;font-family:
等线;mso-ascii-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#212529'>如果您对本政策或我们使用您的个人信息有任何疑问、意见、担忧或投诉，请通过<span class=GramE>上述联系</span>方式与我们联系。</span><span lang=EN-US style='font-size:10.0pt;font-family:"Times New Roman",serif;
color:#212529'></span></p>

          <p class=MsoNormal style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;mso-para-margin-top:.5gd;mso-para-margin-right:0cm;mso-para-margin-bottom:
.5gd;mso-para-margin-left:0cm;line-height:normal'><span lang=EN-US
              style='font-size:10.0pt;font-family:"Times New Roman",serif'></span>
          </p>

          <p class=MsoNormal><span lang=EN-US></span></p>

        </div>

      </div>
      <div style="max-height: 200px;overflow-y: auto" v-else>
        <p><br></p>
        <p style="text-align: center;"><strong>END USER LICENSE AGREEMENT</strong></p>
        <p><strong>CAREFULLY READ THE FOLLOWING TERMS AND CONDITIONS BEFORE USING THE SERVICE.DO NOT ACCESS OR OTHERWISE
            USE THE SERVICE IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT.ACCESSING OR OTHERWISE USING
            ANY PART OF THE SERVICE INDICATES ACCEPTANCE AND AGREEMENT TO THE TERMS AND CONDITIONS OF THIS
            AGREEMENT.</strong></p>
        <p><u><strong>1. &nbsp; &nbsp;SERVICES</strong></u></p>
        <p><span style="color: rgb(33, 37, 41);"><u>1.1 &nbsp;Performance. &nbsp;Subject to the terms and conditions of
              this End User License Agreement (the “Agreement”), Hydro Systems Company (“Hydro”) will provide to you (the
              “User” together with Hydro, the “Parties” and each a “Party”) Hydro’s Hydro Connect software as a service
              (the “Service”) for the sole purpose of monitoring the use and efficiency of the chemical dispensing
              equipment with which the Service is integrated.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>1.2 &nbsp;Security. &nbsp;User will comply with all Company rules and
              regulations and security restrictions in connection with use of the Service.Each User will be assigned a
              unique user identification name and password for access to and use of the Service (“User ID”).User shall be
              solely responsible for ensuring the security and confidentiality of its User ID.User acknowledges that it
              will be fully responsible for all liabilities incurred through use of its User ID and that any transactions
              under its User ID will be deemed to have been performed by User.Use of the User ID other than as provided in
              this Agreement shall be considered a breach of this Agreement by User.User may not share its User ID with
              any other user of the Service.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>1.3 &nbsp;Proprietary Rights. &nbsp;User acknowledges Hydro’s
              proprietary rights in the Service and associated documentation and shall protect the proprietary nature
              thereof.If User suggests any new features, functionality or performance for the Service that Hydro
              subsequently incorporates into the Service (or any other software or service), User hereby acknowledges that
              (i) Hydro shall own, and has all rights to use, such suggestions and the Service (or any other service)
              incorporating such new features, functionality, or performance shall be the sole and exclusive property of
              Hydro; and (ii) all such suggestions shall be free from any confidentiality restrictions that might
              otherwise be imposed upon Hydro.User shall not disassemble, decompile, reverse engineer, modify, transcribe,
              store, translate, sell, resell, lease, or otherwise transfer or distribute the Service or its associated
              documentation, in whole or in part, without prior authorization in writing from Hydro.In the event of any
              breach of this Section 1.3, User agrees that Hydro will suffer irreparable harm and shall therefore be
              entitled to obtain injunctive relief against User.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>1.4 &nbsp;Third-Party Software; Subcontracting. &nbsp;To the extent
              that the Service incorporates any third-party software licensed by Hydro, then, in addition to the terms set
              forth herein, User must comply with any additional terms, restrictions, or limitations applicable to such
              third-party software.Additionally, User acknowledges that Hydro shall have the right to subcontract
              performance of its hosting services, in which event the service terms provided by any third-party hosting
              provider (including, without limitation, any service levels that are stricter than the service levels
              offered by Hydro) will be incorporated herein by reference.</u></span>
        </p>
        <p><u><strong>2. &nbsp; DATA RIGHTS</strong></u></p>
        <p><span style="color: rgb(33, 37, 41);"><u>2.1 &nbsp;User Information. &nbsp; User grants to Hydro a
              non-exclusive license to copy, use and display any and all personally identifiable data, information or
              communications sent, or entered by User while accessing the Service (“User Information”) solely to the
              extent necessary for Hydro to provide the Service.User shall own and, subject to the provisions of Section
              2.3below, retain all right, title and interest, including all intellectual property rights, in and to all
              User Information. User acknowledges that Hydro exercises no control whatsoever over the content of the User
              Information and it is the sole responsibility of User, at its own expense, to provide the information, and
              to ensure that the information User transmits or receives complies with all applicable laws and regulations
              now in place or enacted in the future. Hydro is under no obligation, however, to review User Information for
              accuracy, potential liability or for any other reason.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>2.2 &nbsp;Data Retention. &nbsp;User agrees that Hydro’s obligation to
              keep or maintain any User Information obtained in the course of performance of the Service shall not extend
              beyond the expiration of fourteen (14) days following the termination of this Agreement.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>2.3 &nbsp;Data Collection. &nbsp;Hydro shall have the right to utilize
              data capture, syndication and analysis tools, and other similar tools, to extract, compile, synthesize, and
              analyze any non-personally identifiable data or information (including, without limitation, User
              Information) resulting from User’s access and use of the Service (“Blind Data”).To the extent that any Blind
              Data is collected by Hydro, such Blind Data shall be solely owned by Hydro and may be used by Hydro for any
              lawful business purpose without a duty of accounting to User, including, without limitation, providing User
              with the opportunity for User to benchmark itself against its peers, provided that the Blind Data is used
              only in an aggregated form, without specifically identifying the source of the Blind Data.Without assuming
              any obligations or liabilities of User, Hydro agrees to use commercially reasonable efforts to comply with
              the applicable U.S. laws and regulations respecting the dissemination and use such Blind Data.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>2.4 &nbsp;Privacy Policy. &nbsp;Hydro has reasonable security measures
              in place to protect personally identifiable information and sensitive data that is generated by User’s use
              of the Service and received by Hydro. While no computer system or server is completely secure, Hydro
              believes the measures it has implemented reduce security problems.Accordingly, Hydro’s Privacy Policy
              attached as Exhibit A is hereby incorporated into this Agreement by reference.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>2.5 &nbsp;Data Security. &nbsp;The Service is designed to be connected
              to and to communicate information and data via a network interface. It is User’s sole responsibility to
              provide and continuously ensure a secure connection between the Service and User’s computer and IT network
              or any other network (as the case may be). User shall establish and maintain any appropriate network
              security measures (including, but not limited to the installation of firewalls, installation of antivirus
              programs, and periodic data backups) to protect the Service and User’s systems and network from and against
              any security breaches, unauthorized access, interference, intrusion or theft of data or
              information.</u></span>
        </p>
        <p><u><strong>3. &nbsp;USER OBLIGATIONS</strong></u></p>
        <p><span style="color: rgb(33, 37, 41);"><u>3.1 &nbsp;Assistance. &nbsp;User shall provide all reasonable
              assistance and cooperation requested by Hydro in connection with providing any support or troubleshooting
              assistance concerning the Service.Hydro reserves the right to assess additional fees for any delay caused,
              in whole or in part, by User.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>3.2 &nbsp;Hardware. &nbsp;User shall procure, install and maintain all
              equipment, Internet connections and other hardware (other than the hardware constituting the support center
              maintained at Hydro’s facilities) necessary for User to connect to and access the Service.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>3.3 &nbsp;Conduct. &nbsp;User agrees not to: (i) copy, decompile,
              reverse engineer, disassemble, attempt to derive the source code, modify or create derivative works of the
              Service or any Service related documentation; (ii) upload or distribute in any way files that contain
              viruses, trojans, worms, time bombs, logic bombs, corrupted files, or any other similar software or programs
              that may damage the operation of the Service or another's computer; (iii) use the Service for illegal
              purposes; (iv) interfere or disrupt networks connected to the Service; (v) upload, post, promote or transmit
              through the Service any unlawful, harassing, libelous, abusive, threatening, harmful, vulgar, obscene,
              hateful, racially, ethnically or otherwise objectionable material of any kind or nature; (vi) upload amounts
              of data and/or materials in excess of any limits specified by Hydro from time to time and not to create
              large numbers of accounts or otherwise transmit large amounts of data so as to clog the Service or comprise
              a denial of service attack or otherwise so as to have a detrimental effect on the Services; or (vii) upload,
              promote, transmit or post any material that encourages conduct that could constitute a criminal offence or
              give rise to civil liability.Hydro may take whatever remedial action it determines in its sole discretion is
              appropriate if User violates these restrictions, including, but not limited to, immediate suspension or
              cancellation of the Service.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>3.4 &nbsp;User Compliance Requirements. &nbsp;User agrees
              to:</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(a) &nbsp;Abide by the relevant laws and regulations of China</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(b) &nbsp;Comply with all network protocols, regulations and procedures
            relating to network services</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(c) &nbsp;Obtain all requisite written consents and authorizations
            necessary to provide or otherwise process any data and content through the Service and User represents and
            warrants that all such required consents and authorizations have been obtained;</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(d) &nbsp;Submit accurate, complete and up-to-date information at all
            times in accessing and using the Service;</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(e) &nbsp;Maintain all licenses and permits required to conduct User’s
            business and to immediately notify Hydro if any license or permit is (or is threatened to be) subject to
            discipline or is revoked, terminated or otherwise restricted in any way; and</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(f) &nbsp;Maintain complete and accurate records of User’s compliance
            with the obligations set forth in this Agreement.During User’s use of the Service and for a period of one (1)
            year thereafter, Hydro shall, upon its reasonable request, be given access to all of User’s records,
            documentation, files and inventory pertaining to User’s use of the Program for auditing purposes and to
            determine User’s compliance with this Agreement.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>3.5 &nbsp;Indemnification by User. &nbsp;User hereby irrevocably and
              unconditionally agrees, to the fullest extent permitted by law, to defend, indemnify, and hold harmless
              Hydroand its officers, directors, employees, and agents (the “Hydro Indemnitees”) from and against any and
              all claims, liabilities, losses, costs, damages, and/or expenses of any kind whatsoever (including
              reasonable attorney’s fees) that HydroIndemnitees may incur directly or indirectly, wholly or partially
              arising from or in connection with (i) User’s non-compliance with law; and (ii) its use of the Service
              (other than for claims of infringement for which Hydro owes User an obligation of indemnification pursuant
              to Section 5.1 below). This Section shall survive the termination or expiration of this
              Agreement.</u></span>
        </p>
        <p><u><strong>4. &nbsp;SERVICE LEVELS; WARRANTY DISCLAIMER</strong></u><span
            style="color: rgb(33, 37, 41);">.HYRDO SHALL PERFORM THE SERVICES IN ACCORDANCE WITH ITS THEN CURRENT SERVICE
            LEVEL AGREEMENT.EXCEPT FOR THE FOREGOING, THE SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE” WITH ALL FAULTS
            AND WITHOUT WARRANTY OF ANY KIND. &nbsp;HYDRO MAKES NO REPRESENTATION OR WARRANTY (EXPRESS, IMPLIED OR
            STATUTORY) WITH RESPECT TO THE SERVICE, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF FITNESS FOR A
            PARTICULAR PURPOSE, ACCURACY MERCHANTABILITY, OR NONINFRINGEMENT. &nbsp;HYDRO DOES NOT WARRANT THAT THE
            SERVICE WILL OPERATE UNINTERRUPTED OR ERROR-FREE.</span>
        </p>
        <p><u><strong>5. &nbsp;INFRINGEMENT AND INDEMNIFICATION</strong></u></p>
        <p><span style="color: rgb(33, 37, 41);"><u>5.1 &nbsp;Infringement Indemnity. &nbsp;Hydro will defend and
              indemnify User against third-party claims that the Service used by User in accordance with this Agreement
              infringes or misappropriates the third-party’s intellectual property rights in the United States,
              providedthat: (a) User agrees to notify Hydro promptly in writing of User’s knowledge of a claim; (b) Hydro
              has sole control of the defense and all related settlement negotiations; and (c) User shall provide Hydro
              with the assistance, information, and authority reasonably necessary to perform such defense.Hydro shall
              have no liability for any claim of infringement resulting from: (i) User’s alteration or modifications of
              the Service without Hydro’s prior written approval (ii) User Information; or (iii) the combination or use of
              the Service with software, data, or material not furnished by Hydro.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>5.2 &nbsp;Mitigation/Termination. &nbsp;In the event that some or all
              of the Service is held or is reasonably believed by Hydro to infringe the rights of a third party, Hydro
              shall have the option, at its expense, to: (i) modify the Service to make it non-infringing; or (ii) obtain
              a license that permits User to continue using the Service.If neither of such options can be exercised by
              Hydro on a commercially reasonable basis and the infringing materials materially affect the Service or the
              ability of Hydro to meet its obligations under this Agreement, then Hydro may terminate this
              Agreement.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>5.3 &nbsp;Exclusive Remedy. &nbsp;This Section 5states Hydro’s entire
              liability and exclusive remedy for infringement of third-party intellectual property rights.</u></span>
        </p>
        <p><u><strong>6. &nbsp;LIMITATION OF LIABILITY</strong></u><span style="color: rgb(33, 37, 41);">.IN NO EVENT
            SHALL HYDRO BE LIABLE FOR ANY INCIDENTAL, PUNITIVE, EXEMPLARY, SPECIAL, RELIANCE, CONSEQUENTIAL DAMAGES
            (INCLUDING WITHOUT LIMITATION, DAMAGES ARISING FROM LOSS OR CORRUPTION OF DATA, LOSS OF PROFITS OR GOODWILL,
            BUSINESS INTERRUPTION OR INTERFERENCE, OR INABILITY TO USE OR INTERFERENCE WITH USE OF SERVICE) REGARDLESS OF
            WHETHER IT WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.IN NO EVENT SHALL HYDRO’S TOTAL LIABILITY UNDER THIS
            AGREEMENT OR IN CONNECTION WITH THE SERVICES EXCEED THE GREATER OF (I) $100 OR (II) THE AMOUNTS PAID FOR
            ACCESS AND USE OF THE SERVICE BY USER TO HYDRO IN THE SIX (6) MONTH PERIOD IMMEDIATELY PRIOR TO A CLAIM
            ARISING HEREUNDER.</span>
        </p>
        <p><u><strong>7. &nbsp;TERM; TERMINATION</strong></u></p>
        <p><span style="color: rgb(33, 37, 41);"><u>7.1 &nbsp;Termination by Hydro. &nbsp; Hydro may terminate this
              Agreement at any time, with or without cause, upon notice to User.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>7.2 &nbsp;Modification of Cessation of Services. &nbsp;Hydro may
              terminate this Agreement, at any time, in the event that Hydro ceases to provide the Services.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>7.3 &nbsp;Effect of Termination. &nbsp;Upon termination of the
              Agreement for any reason, User’s right to use the Service shall immediately cease.Termination of this
              Agreement shall not relieve either party of any obligation accrued prior to the termination date.Termination
              shall not affect the obligations of the Parties under Sections 1.3, 2.3, 3.4, 3.5, 4, 6, 7.3, 8, 9.
              &nbsp;</u></span>
        </p>
        <p><u><strong>8. &nbsp;CONFIDENTIAL INFORMATION</strong></u></p>
        <p><span style="color: rgb(33, 37, 41);"><u>8.1 &nbsp;Definition.“Confidential Information” means the Service, its
              associated documentation, Hydro’s pricing for the Service, and other information disclosed by Hydro under
              this Agreement that is designated as confidential or that by its nature would reasonably be expected to be
              kept confidential.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>8.2 &nbsp;Exclusions. &nbsp;Notwithstanding the previous paragraph,
              Hydro’s Confidential Information shall not include information that (i) is or becomes publicly available
              through no act or omission of User; or (ii) was in User’s lawful possession prior to the disclosure and had
              not been obtained by User either directly or indirectly from Hydro; or (iii) is lawfully disclosed to User
              by a third party not bound by a duty of non-disclosure; or (d) is independently developed by User without
              access to or use of the Confidential Information.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>8.3 &nbsp;Nondisclosure. &nbsp; User agrees to hold the Confidential
              Information in confidence.User agrees not to make the Confidential Information available in any form to any
              third party or to use the Confidential Information for any purpose other than performing its obligations or
              enjoying its rights under this Agreement.User agrees to use the same degree of care that it uses to protect
              its own confidential information of a similar nature and value, but in no event less than a reasonable
              standard of care, to ensure that Confidential Information is not disclosed or distributed by its employees
              or agents in violation of the provisions of this Agreement.User represents that it has, with each of its
              employees who may have access to any Confidential Information, an appropriate agreement sufficient to enable
              it to comply with all of the terms of this Section 8. &nbsp;</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>8.4 &nbsp;Required Disclosure. &nbsp; Notwithstanding the foregoing,
              User may disclose the Confidential Information to the extent that such disclosure is required by law or
              court order, provided, however, that User provides to Hydro prior written notice of such disclosure and
              reasonable assistance in obtaining an order protecting the Confidential Information from public
              disclosure.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>8.5 &nbsp;Termination/Expiration. &nbsp;After termination or
              expiration of this Agreement, User shall return to Hydro any Confidential Information in User’s possession
              or control.</u></span>
        </p>
        <p><strong>9. &nbsp;MISCELLANEOUS</strong></p>
        <p><span style="color: rgb(33, 37, 41);"><u>9.1 &nbsp;Relationship Between the Parties. &nbsp;This Agreement shall
              not be construed as creating any agency, partnership, joint venture, or other similar legal relationship
              between the Parties; nor will either Party hold itself out as an agent, partner, or joint venture party of
              the other party.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>9.2. &nbsp;Compliance with Law. &nbsp;Each Party shall comply with all
              applicable laws and regulations of governmental bodies or agencies in its performance under this
              Agreement.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>9.3. &nbsp;Notice. &nbsp;Whenever notice is required to be given to
              Hydro under this Agreement, such notice shall be in writing and shall be addressed toHydro Systems Company,
              3798 Round Bottom Road, Cincinnati, OH 45244, Attention: [Elliott Liddle].Hydro may provide any required
              notice to User to the email address provided by User and associated with User’s User ID.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>9.4. &nbsp;Waiver. &nbsp;No waiver shall be implied from conduct or
              failure to enforce rights.No waiver shall be effective unlessin a writing signed by both Parties.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>9.5. &nbsp;Severability. &nbsp;If any provision of this Agreement is
              held to be invalid, void or unenforceable, such provision shall be deemed to be restated to reflect as
              nearly as possible the original intentions of the parties in accordance with applicable law, and the
              remaining provisions of this Agreement shall remain in full force and effect.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>9.6. &nbsp;Assignment. &nbsp;User may not assign or delegate any of
              its rights, interest or obligations hereunder, whether by operation of law or otherwise, without the prior
              written consent of Hydro.The sale of a controlling interest in User through a single transaction or a series
              of transactions shall be deemed an assignment hereunder for which Hydro’s consent is required.Hydro may
              assign and delegate this Agreement to successors in the event of a merger, acquisition or other change in
              control.This Agreement shall bind and inure to the benefit of the parties and their respective successors
              and permitted assigns.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>9.7. &nbsp;Governing Law. &nbsp;This Agreement and all claims related
              to it, its execution or the performance of the parties under it, shall be construed and governed in all
              respects according to the laws of the State of Illinois without regard to the conflict of law provisions
              thereof. Any action or proceeding arising out of or relating to this Agreement shall be brought exclusively
              in a state or federal court of competent jurisdiction located within the State of Illinois in the County of
              Cook.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>9.8. &nbsp;Force Majeure. &nbsp;Hydro shall not be in default or
              otherwise liable for any delay in or failure of its performance under this Agreement where such delay or
              failure arises by reason of any Act of God, or any government or any governmental body, war, insurrection,
              acts of terrorism, the elements, strikes or labor disputes, or other similar or dissimilar causes beyond
              Hydro’s control.User acknowledges that the performance of certain Hydro obligations may require the
              cooperation of third parties designated by User and outside the control of Hydro.In the event such third
              parties fail to cooperate with Hydro in a manner that reasonably permits Hydro to perform its obligations,
              such failures shall be considered as causes beyond the control of Hydro for the purposes of this Section and
              shall not be the basis for a determination that Hydro is in breach of any of its obligations under this
              Agreement or is otherwise liable.Either Party may terminate at its option this Agreement if any such
              situation continues for thirty (30) days and prevents the continued performance of this Agreement by the
              other Party.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>9.9 &nbsp;Dispute Resolution. Any dispute arising out of or in
              connection with this Agreement shall be settled by the parties through friendly negotiation. If no agreement
              can be reached through negotiation, either party shall submit the dispute to Shanghai International Economic
              and Trade Arbitration Commission for arbitration in accordance with its arbitration rules in effect at that
              time. The arbitral award shall be final and binding upon all parties.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"><u>9.10 &nbsp;Entire Agreement. &nbsp;This Agreement shall constitute the
              complete agreement between the Parties with respect to User’s access and use of the Service and supersedes
              all previous agreements or representations, written or oral, with respect to the subject matter hereof. This
              Agreement may not be modified or amended exceptin a writing signed by a duly authorized representative of
              each party. It is expressly agreed that any terms and conditions of any purchase order or similar instrument
              of User shall be superseded by the terms and conditions of this Agreement to the extent that such terms may
              be in conflict.</u></span>
        </p>
        <p><span style="color: rgb(33, 37, 41);"> </span></p>
        <p><span style="color: rgb(33, 37, 41); background-color: white;"> </span></p>
        <p><strong>Privacy Policy</strong></p>
        <p><span style="color: rgb(33, 37, 41);"> </span></p>
        <p><em>Effective Date: July 1, 2022</em></p>
        <p><span style="color: rgb(33, 37, 41);">Hydro Systems Company (“Hydro”) respects your individual privacy. This
            Privacy Policy puts you in control of how your personal information is used at Hydro, including in connection
            with our global websites including, </span><span
            style="color: black;">www.hydroconnect.hydrosystemschina.com</span><span
            style="color: rgb(33, 37, 41); font-family: 宋体;">, </span><span
            style="color: black;">www.hydrosystemschina.com</span><span style="color: rgb(33, 37, 41);"> and </span><span
            style="color: black;">www.psgdover.com.cn</span><span style="color: rgb(33, 37, 41);"> &nbsp;(“Hydro Web
            Site”). This Privacy Policy describes how we collect, use, and share personal information we obtain off and
            online.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">If you need a copy of this Privacy Policy in an alternative accessible
            format, please contact at us at &nbsp;</span><span style="color: black;">PSG-China@psgdover.com</span><span
            style="color: rgb(33, 37, 41);"> or you can call us at 400 600 4026.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">If you are an employee of Hydro or a Hydro Affiliate, you should refer to
            the Employee Privacy Notice provided by your employer.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">This Privacy Policy is complemented by our Terms of Use available at
            &nbsp;</span><span style="color: black;">https://www.hydrosystemschina.com</span><span
            style="color: rgb(33, 37, 41);"> &nbsp;and </span><span
            style="color: black;">https://www.psgdover.com.cn</span><span style="color: rgb(33, 37, 41);"> </span></p>
        <p><u>Table of Contents</u></p>
        <p><span style="color: rgb(33, 37, 41);">1. &nbsp; Who we are and contact details</span></p>
        <p><span style="color: rgb(33, 37, 41);">2. &nbsp; What personal information we collect</span></p>
        <p><span style="color: rgb(33, 37, 41);">3. &nbsp; What we do with the personal information we gather</span></p>
        <p><span style="color: rgb(33, 37, 41);">4. &nbsp; Marketing</span></p>
        <p><span style="color: rgb(33, 37, 41);">5. &nbsp; Data Sharing</span></p>
        <p><span style="color: rgb(33, 37, 41);">6. &nbsp; Lawful Bases</span></p>
        <p><span style="color: rgb(33, 37, 41);">7. &nbsp; Security and Confidentiality</span></p>
        <p><span style="color: rgb(33, 37, 41);">8. &nbsp; How long will we hold your personal information for</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">9. &nbsp; Transfers of personal information</span></p>
        <p><span style="color: rgb(33, 37, 41);">10. &nbsp; Links to other websites</span></p>
        <p><span style="color: rgb(33, 37, 41);">11. &nbsp; Rights related to your personal information</span></p>
        <p><span style="color: rgb(33, 37, 41);">12. &nbsp; Persons under 18</span></p>
        <p><span style="color: rgb(33, 37, 41);">13. &nbsp; Cookies</span></p>
        <p><span style="color: rgb(33, 37, 41);">14. &nbsp; Any concerns?</span></p>
        <p><span style="color: rgb(33, 37, 41);"> </span></p>
        <p><span style="color: rgb(33, 37, 41);">1.WHO WE ARE AND CONTACT DETAILS</span></p>
        <p><span style="color: rgb(33, 37, 41);">This is the website of</span></p>
        <p><span style="color: rgb(33, 37, 41);">PSG (TIANJIN) CO., LTD</span></p>
        <p><span style="color: rgb(33, 37, 41);">NO.2 HUAKE ER ROAD, HAITAI HUAYUAN TECHNICAL PARK </span></p>
        <p><span style="color: rgb(33, 37, 41);">TIANJIN, PRC</span></p>
        <p><span style="color: rgb(33, 37, 41);">You can contact us via e-mail at: &nbsp;</span><span
            style="color: black;">PSG-China@psgdover.com</span><span style="color: rgb(33, 37, 41);"> or you can call us
            at 400 600 4026.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">2.WHAT PERSONAL INFORMATION WE COLLECT</span></p>
        <p><span style="color: rgb(33, 37, 41);">We are a business facing company and collect the following information
            about our business contacts depending on the manner in which they interact with Hydro, including through the
            Hydro Web Site or any other Hydro online services:<br> <br> (i) Contact information – that is, information
            such as your full name, email address, mailing address, telephone number and information about your query;<br>
            <br> (ii) Online identifiers, such as information about the device you use to access the Hydro Web Site (e.g.
            IP address), and information about how you interact with our sites and services.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">3.WHAT WE DO WITH THE PERSONAL INFORMATION WE GATHER</span></p>
        <p><span style="color: rgb(33, 37, 41);">We use the personal information we gather about you for the following
            purposes:</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(i) &nbsp; &nbsp;to understand your needs, provide you with a better
            service, and respond to your inquiries;</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(ii) &nbsp; &nbsp;Subject to the provisions below, we will also use your
            personal information for other marketing-related purposes;</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(iii) &nbsp; &nbsp;To improve the products and services offered through
            the Hydro Web Site;</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(iv) &nbsp; &nbsp;To measure the number of visitors to the Hydro Web Site
            and the areas of the Web Site that are of most interest to visitors and to customize the Hydro Web Site;
            and</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(v) &nbsp; &nbsp;To determine who and where to send Hydro Connect
            communications, such as mobile alerts, account information, and system downtime notifications</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">We do not use the personal information that you provide through the Hydro
            Web Site to make automated decisions about you.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">To the extent permitted by applicable law, Hydro may also use your
            personal information as we believe to be necessary or appropriate:</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(a) under applicable law, including laws outside your country of
            residence;</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(b) to comply with legal process;</span></p>
        <p><span style="color: rgb(33, 37, 41);">(c) to respond to requests from public and government authorities
            including public and government authorities outside your country of residence;</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(d) to enforce our terms and conditions;</span></p>
        <p><span style="color: rgb(33, 37, 41);">(e) to protect our operations or those of any of our affiliates;</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(f) to protect our rights, privacy, safety or property, and/or that of
            our affiliates, you or others; and</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">(g) to allow us to pursue available remedies or limit the damages that we
            may sustain.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">4. &nbsp; &nbsp;MARKETING</span></p>
        <p><span style="color: rgb(33, 37, 41);">Where required by local law, we will obtain your prior consent:</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">a. &nbsp; &nbsp;in order to send you marketing communications about
            Hydro;</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">b. &nbsp; &nbsp;in order to provide your personal information to Hydro
            Affiliates for their marketing purposes; and</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">c. &nbsp; &nbsp;in order to provide your personal information to third
            parties and other partners for joint marketing efforts or their own marketing efforts.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">Please note that you always have the right to withdraw your
            consent.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">Otherwise we may contact you by email, phone, fax, mail, or other means
            unless you direct us not to do so.<br> <br> We may use your personal information for our own marketing
            purposes, including, but not limited to notifying you of new services, offers or other information via mail,
            email, telephone, text message, and other means.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">If you do not want us to use your personal information for marketing
            purposes or to provide your personal information to Hydro Affiliates or to third parties for their marketing
            purposes, you may opt-out of such uses by notifying us in accordance with the procedure set forth below under
            the heading, “Rights to your Personal Information.”</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">5. &nbsp; &nbsp;DATA SHARING <br> We may share your personal information
            with our affiliates (i.e., affiliated businesses and operating companies within Dover) (collectively, "Hydro
            Affiliates") in pursuance of the business and commercial purposes set out in section 3 above.<br> <br> We may
            share your personal information with third party service providers (including contractors who provide services
            to us) to assist with any of the uses of personal information described above. For example, we may share
            and/or provide your personal information with/to third parties that assist with the operation, administration,
            or maintenance of the Hydro Web Site or for pre-employment screening.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">We may also disclose your personal information to third parties if
            disclosure is required to comply with applicable laws or regulations; comply with legal process or a court
            order; to respond to requests from public and government authorities; or if disclosure is necessary to enforce
            our Privacy Policy and/or Terms of Use; to protect your safety or security; to protect our rights, the safety
            and security of our site and property or that of Hydro Affiliates or third parties; to allow us to pursue
            available remedies or limit the damages we may sustain.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">We do not sell personal information to third parties.However, we may
            share or transfer your personal information to a third party if Hydro enters into, or is involved in, a
            business transaction such as a merger, acquisition, reorganization, or sale of some or all its assets.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">6.LAWFUL BASES</span></p>
        <p><span style="color: rgb(33, 37, 41);">Under the laws in China, we are required to identify the lawful bases for
            which we use your personal information. These are:</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">· &nbsp; &nbsp;Legitimate Interest – we have a legitimate interest in
            contacting you where you provide us with your details, to manage our relationship with you and to monitor and
            improve the use of the Hydro Web Site and to send you marketing</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">· &nbsp; &nbsp;Consent – where we are required by local law to collect
            consent in order to send you marketing communications</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">· &nbsp; &nbsp;Compliance with a legal obligation – where we are required
            to collect and disclose your personal information because we are subject to a legal or regulatory
            obligation</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">We do not intend to collect any sensitive personal information (which is
            defined under EU law to include, for example, personal information on ethnicity, racial origin and health)
            through the Hydro Web Site.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">Where we rely on the legitimate interest lawful basis to use your
            personal information, you always have the right to object to such use by contacting us using the details
            above.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">Where we rely on your consent to use your personal information, you
            always have the right to withdraw your consent at any time by contacting us using the details above.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">7.SECURITY AND CONFIDENTIALITY</span></p>
        <p><span style="color: rgb(33, 37, 41);">We are committed to ensuring that your personal information is secure. In
            order to prevent unauthorized access or disclosure, we have put in place reasonable security procedures and
            practices appropriate to the nature of the information, including physical, electronic, and managerial
            procedures to safeguard and secure the personal information we collect online. However, we are not able to
            guarantee the confidentiality of non-confidential data such as feedback, questions, comments or ideas that you
            voluntarily provide to us (e.g., via the “Contact” tab or similar features on the Hydro Web Site). We reserve
            the right to use and disclose such non-confidential information as we deem appropriate in compliance with
            applicable law.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">8.HOW LONG WILL WE HOLD YOUR PERSONAL INFORMATION FOR</span></p>
        <p><span style="color: rgb(33, 37, 41);">We will retain your personal information for as long as:</span></p>
        <p><span style="color: rgb(33, 37, 41);">· &nbsp; &nbsp;we have a relationship with you;</span></p>
        <p><span style="color: rgb(33, 37, 41);">· &nbsp; &nbsp;we are required by law; or</span></p>
        <p><span style="color: rgb(33, 37, 41);">· &nbsp; &nbsp;we need to in order to protect ourselves as a business or
            protect any other third party.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">If you have any questions about our retention policyplease contact us
            using the details set out above.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">9. &nbsp;Transfers of personal information</span></p>
        <p><span style="color: rgb(33, 37, 41);">As a global organisation, we will transfer your personal information
            across borders. Hydro is based in the United States although our Hydro Affiliates are located in countries
            worldwide. Where a transfer of personal information across borders is required to comply with applicable laws,
            we will implement appropriate or suitable safeguards to protect that personal information. Please contact us
            using our details above should you require further information.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">10. &nbsp; &nbsp;LINKS TO OTHER WEB SITES</span></p>
        <p><span style="color: rgb(33, 37, 41);">The Hydro Web Site may contain links to enable you to visit other
            websites of interest easily. However, once you have used these links to leave our site, you should note that
            we do not have any control over that other web site. Therefore, we cannot be responsible for the protection
            and privacy of any personal information which you provide whilst visiting such sites and such sites are not
            governed by this Privacy Policy. You should exercise caution and look at the privacy statement applicable to
            the web site in question.</span>
        </p>
        <p><span style="color: rgb(33, 37, 41);">11. &nbsp; &nbsp;rights RELATED to YOUR PERSONAL INFORMATION</span></p>
        <p><span style="color: rgb(33, 37, 41);">Under certain laws, you have the right to seek information about or
            access to your personal information, seek rectification or erasure of your personal information, restrict
            certain uses or disclosures of your personal information, object to our use or request data portability.
            Please contact us if you wish to exercise any of these rights.</span>
        </p>
      <p><span style="color: rgb(33, 37, 41);">In any event, you can always ask us for more information about the people
          who will be able to see and access your personal information.</span>
      </p>
      <p><span style="color: rgb(33, 37, 41);">You may choose to restrict the collection or use of your personal
          information by us by emailing us at </span><span style="color: black;">PSG-China@psgdover.com</span><span
          style="color: rgb(33, 37, 41);">. This includes where you:</span>
      </p>
      <p><span style="color: rgb(33, 37, 41);">· &nbsp; &nbsp;have previously not objected to us using your personal
          information for our direct marketing purposes but now wish to opt-out of receiving future marketing emails
          from us;</span>
      </p>
      <p><span style="color: rgb(33, 37, 41);">· &nbsp; &nbsp;want to opt-out of any new uses of personal information
          permitted by this Privacy Policy caused by changes in our information practices;</span>
      </p>
      <p><span style="color: rgb(33, 37, 41);">· &nbsp; &nbsp;do not want us to share your personal information with
          Hydro Affiliates and/or our business (and other) partners for marketing purposes; </span>
      </p>
      <p><span style="color: rgb(33, 37, 41);">· &nbsp; &nbsp;believe that personal information we are holding on you is
          incorrect or incomplete so that we can consider your concern and, where appropriate, we will promptly correct
          any personal information found to be incorrect. Or, you may contact us if you wish to review or change your
          personal Information by using the “Contact” tab on our home page; and </span>
      </p>
      <p><span style="color: rgb(33, 37, 41);">· &nbsp; &nbsp;do not want to be contacted by us in the future (unless
          you initiate the contact).</span>
      </p>
      <p><span style="color: rgb(33, 37, 41);">You may also write to us at the above address (located at the beginning
          of this Privacy Policy) or call us at 400 600 4026.</span>
      </p>
      <p><span style="color: rgb(33, 37, 41);">From time to time, we may wish to use your personal information collected
          through the Hydro Web Site for new purposes. If the new purpose is incompatible with the purposes stated
          above, we will take reasonable steps to notify you of the new purpose.</span>
      </p>
      <p><span style="color: rgb(33, 37, 41);">12. &nbsp; &nbsp;PERSONS UNDER 18</span></p>
      <p><span style="color: rgb(33, 37, 41);">We do not knowingly collect or allow the collection of personal
          information from persons under the age of 18. If you are the parent or guardian of a person under the age of
          18 and believe that the person has provided personal information to us, please contact us at </span><span
          style="color: black;">PSG-China@psgdover.com</span><span style="color: rgb(33, 37, 41);">.</span></p>
      <p><span style="color: rgb(33, 37, 41);">13. &nbsp; &nbsp;COOKIES</span></p>
      <p><span style="color: rgb(33, 37, 41);">When you visit the Hydro Web Site, we may use certain functional
          “cookies” and similar technologies to help us recognize and serve you better. Cookies are small files that
          contain information sent by a web site that is saved on your computer’s hard drive. You may delete cookies
          from your computer or set your browser to reject cookies. However, doing so may limit some functionalities of
          our site. Please see our Cookies Policy for more information about our use of these technologies on the Hydro
          Web Site.</span>
      </p>
      <p><span style="color: rgb(33, 37, 41);">14. &nbsp; &nbsp;any concerns?</span></p>
      <p><span style="color: rgb(33, 37, 41);">If you have any questions, comments, concerns or complaints about this
          policy or our use of your personal information, please contact us using the contact details set out
          above.</span>
      </p>
      <p><span style="color: rgb(33, 37, 41);">You also have a right to complain to your local data protection
          regulator.</span>
      </p>
      <p></p>
      <p><br></p>
    </div>

    <span slot="footer" class="dialog-footer">
      <!--    <el-button @click="dialogVisible = false">取 消</el-button>-->
      <el-button type="primary" @click="ok">{{ fun.getLanguageText($store.state.language, '同意') }}</el-button>
    </span>
  </el-dialog>
  <el-dialog :close-on-click-modal="false" :show-close="false" :visible.sync="dialogVisibleChangePSW"
    :title="fun.getLanguageText($store.state.language, '修改密码')" width="30%">
    <el-input v-model="changePsw.secret" :placeholder="fun.getLanguageText($store.state.language, '请输入旧密码')"
      type="password"></el-input>
    <el-input v-model="changePsw.newSecret" :placeholder="fun.getLanguageText($store.state.language, '请输入新密码')"
      type="password"></el-input>
    <el-input v-model="changePsw.confirmSecret" :placeholder="fun.getLanguageText($store.state.language, '请输入确认新密码')"
      type="password"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisibleChangePSW = false">{{fun.getLanguageText($store.state.language,
        '取消')}}</el-button>
      <el-button type="primary" @click="changePwdngePwd">{{fun.getLanguageText($store.state.language,
        '确定')}}</el-button>
    </span>
  </el-dialog>
</el-container></template>
<script>
export default {
  data() {
    return {
      router: true,
      active: "/Home/Home",
      username: '',
      role: '',
      dialogVisible: false,
      dialogVisibleChangePSW: false, // 修改密码弹窗
      changePsw: {
        secret: "",//密码
        newSecret: "", // 新密码
        confirmSecret: "" // 确认新密码
      }
    }
  },
  watch: {
    dialogVisibleChangePSW(val) {
      if (!val) {
        this.changePsw.secret = ""
        this.changePsw.newSecret = ""
        this.changePsw.confirmSecret = ""
      }
    }
  },
  beforeUpdate() {
    // console.log(this.$route.path.split('/')[4])
    let bool = false
    const d_list = ['AddUserNameList', 'AddUserList', 'AddDevice']
    this.$route.path.split('/').map(item => {
      if (d_list.includes(item)) {
        bool = true
      }
    })
    if (!bool) {
      window.localStorage.setItem('active', this.$route.path)
      this.active = this.$route.path
    }
  },
  created() {
    if (localStorage.getItem('ok') !== "1") {
      this.dialogVisible = true
    }
    this.active = window.localStorage.getItem('active')

    let bool = false
    const d_list = ['AddUserNameList', 'AddUserList', 'AddDevice']
    this.$route.path.split('/').map(item => {
      if (d_list.includes(item)) {
        bool = true
      }
    })
    if (!bool) {
      window.localStorage.setItem('active', this.$route.path)
      this.active = this.$route.path
    }
    console.log(this.active, "----active----")
    this.username = localStorage.getItem('username')
    console.log(localStorage.getItem('role'))
    let role = localStorage.getItem('role')
    if (role === 'admin') {
      this.$store.state.roleId = 2
    }
    if (role === 'customer') {
      this.$store.state.roleId = 3
    }
    if (role === 'client') {
      this.$store.state.roleId = 4
    }
    if (role === 'super') {
      this.$store.state.roleId = 1
    }
  },
  methods: {
    // 修改密码
    async changePwdngePwd() {
      let res = await this.$http.post('user/change-pwd',
        this.changePsw
      )
      if (res.data.code !== 2000) {
        this.$message.error(this.fun.getLanguageText(this.$store.state.language, res.data.msg))
        // 读取公司列表失败
        return false
      }
      this.$message.success(this.fun.getLanguageText(this.$store.state.language, '修改密码成功'))
      this.loginout()
      this.dialogVisibleChangePSW = false
      return true
    },
    // 统一
    ok() {
      this.dialogVisible = false
      localStorage.setItem("ok", "1")
    },
    command1(e) {
      if (e === "1") {
        this.$store.state.language = 1
      }
      if (e === "2") {
        this.$store.state.language = 2
      }
    },
    // 点击菜单事件
    command(e) {
      if (e === "1") {
        // 修改密码
        this.dialogVisibleChangePSW = true
      }
      if (e === "2") {
        // 弹出信息
        this.dialogVisible = true
      }
    },
    select(e) {
      //JSON.parse(e)
      this.active = e
    },
    loginout() {
      localStorage.clear()
      this.$router.push('/')
    }
  }
};
</script>

<style lang="less" scoped>



.el-container {
  display: flex;
}

.el-aside {
  width: 200px;
  height: 100%;
}

.el-header {
  padding: 0 20px 0 0;
  box-sizing: border-box;
  height: 100px;
  // background: #FFFFFF;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(204, 204, 204, 0.6);
}

.header-logo {
  width: 160px;
  height: 40px;
  background-image: url(../assets/LOGO1.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 5px;
  margin-left: 29px;
}

.header-title {
  font-size: 14px;
  color: #000;
  font-weight: 700;
  opacity: 0.65;
  margin-left: 10px;
}

.header-left {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.header-right {
  font-size: 14px;

  .el-button,
  .el-link {
    margin-left: 30px;
  }
}

.el-card {
  width: 100%;
  height: 99%;
}


.el-tag {
  font-size: 18px;
  margin-bottom: 20px;
}

.el-breadcrumb {
  height: 40px;
}

.el-input+.el-input {
  margin-top: 20px;
}</style>
